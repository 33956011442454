/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components';
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../../constants';
import { CloseButtonInModal } from '../../common/CloseButtonInModal';
import { StyledButton } from '../../common/StyledButton';
import TransitioningModal from '../TransitioningModal';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FEED_PATH } from '../../../pathNames';
import { FaArrowDown } from 'react-icons/fa6';
import { useGeneralContext } from '../../../context/GeneralContextProvider';
import { GetGoldButton } from '../TopMenuModals/GetSubscriptionModal';
import { desktopMediaQuery } from '../../../styleHelpers';

interface I {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const NotificationModal = ({ showModal, setShowModal }: I) => {
  const handleClose = () => {
    setShowModal(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  return (
    <>
      <Modal
        isOpen={showModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 100,
          },
          content: {
            position: 'relative',
            backgroundColor: BACKGROUND_COLOR,
            padding: '4px',
            height: '90vh',
            width: '90vw',
            maxHeight: '550px',
            maxWidth: '800px',
            margin: 'auto',
            marginTop: '1vh',
            paddingBottom: '4px',
          },
        }}
        ariaHideApp={false}
        shouldFocusAfterRender={false}
        onRequestClose={handleClose}
      >
        <CloseButtonInModal
          style={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            color: PRIMARY_TEXT_COLOR,
          }}
          onClick={handleClose}
        />

        {showMoreInfo ? (
          <MoreInfo />
        ) : (
          <ShorterContent handleClose={handleClose} />
        )}
      </Modal>
    </>
  );
};

/*
<OKButton onClick={handleClose}>OK ✅</OKButton>
const OKButton = styled(StyledButton)`
  border-color: ${PRIMARY_COLOR};
`;
*/

const ButtonsContainer = styled.div`
  display: flex;
  // on top of each other
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const ShowMoreButton = styled(StyledButton)`
  border: 1px solid ${BORDER_COLOR};
  margin-top: 16px;
`;

const CTAButton = styled(ShowMoreButton)`
  border: 1px solid ${PRIMARY_COLOR};
`;

const Modal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
`;

const ModalText = styled.div`
  font-size: 16px;
  color: ${PRIMARY_TEXT_COLOR};
  text-align: center;
  margin-bottom: 16px;

  text-align: left;
`;

const List = styled(ModalText)`
  margin-top: -8px;
`;

const TitleText = styled(ModalText)`
  font-size: 18px;
  margin-bottom: 8px;
  text-align: center;

  ${desktopMediaQuery} {
    font-size: 24px;
  }
`;

const ShorterContent = ({ handleClose }: { handleClose: () => void }) => {
  const { setGoldModalOpen } = useGeneralContext();
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const calculateRemainingSubscriptions = () => {
    const startDate = new Date('2025-02-20');
    const today = new Date();
    const diffDays = Math.floor(
      (today.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
    );

    // Calculate total decrease based on alternating 12/13 pattern
    let totalDecrease = 0;
    for (let i = 0; i < diffDays; i++) {
      totalDecrease += i % 2 === 0 ? 14 : 17;
    }

    return Math.max(169 - totalDecrease, 0);
  };

  const openGoldModal = () => {
    setGoldModalOpen(true);
    handleClose();
  };

  const handleCloseModal = () => {
    handleClose();
  };

  const handleScrollDown = () => {
    // scroll to as down as you can
    ref.current?.scrollTo({
      top: ref.current?.scrollHeight,
      behavior: 'smooth',
    });
  };

  if (calculateRemainingSubscriptions() === 0) {
    handleClose();
  }
  return (
    <ScrollableDiv ref={ref}>
      <TitleText>Spring Discount! 🌸</TitleText>

      <ResponsiveContainer>
        <StyledImage src={'/discountFeb25Image.webp'} loading="lazy" />
        <List>
          <b>⭐ Get Gold Subscription First Month -50% OFF!</b>
          <p>🚫 Cancel anytime</p>
          <p>🎯 First 200 subscriptions only!</p>
          <p>⏳ {calculateRemainingSubscriptions()} subscriptions left</p>
        </List>
      </ResponsiveContainer>
      <ButtonsContainer>
        <GetGoldButton onClick={openGoldModal}>
          Get Gold Subscription -50%
        </GetGoldButton>
      </ButtonsContainer>
    </ScrollableDiv>
  );
};

const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;

  ${desktopMediaQuery} {
    flex-direction: row;
    justify-content: center;
    gap: 2vw;
    max-width: 80vw;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

const StyledImage = styled.img`
  max-height: 200px;
  border-radius: 16px;
  margin-bottom: 16px;

  ${desktopMediaQuery} {
    max-height: 300px;
    margin-bottom: 0px;
  }
`;

const MoreInfo = () => (
  <ScrollableDiv>
    <TitleText>🎨 Styles (beta)</TitleText>

    <ModalText>
      Styles-feature makes it much easier to generate great images without
      adding 500 characters of "prompt magic".
    </ModalText>

    <br />
    <List>
      <b>Under the hood, it works by:</b>
      <p>✨ Adding hidden prompt before and after user prompt</p>
      <p>🚫 Throwing in a hidden negative prompt</p>
      <p>🔧 Changing the engine</p>
    </List>
    <br />

    <ModalText>
      I suggest trying out the default styles and NOT adding any extra prompts,
      such as (detailed face and eyes) or negative prompts, such as (deformed)
    </ModalText>

    <ModalText>
      Click the edit 🖌️ buttons in the More menu to learn how the styles are
      formed, and then create your own!
    </ModalText>

    <ModalText>
      Note: you can skip using styles by using the "None" style
    </ModalText>

    <br />

    <ModalText style={{ marginTop: '16px' }}>
      ⭐ But wait, there's more: I'm opening up all Gold engines for free!
    </ModalText>

    <ModalText>
      Please bear with me as I continue to improve the feature and fix bugs.
      Give me feedback through Discord, Reddit or Email. I just had a baby so I
      don't have energy to work too much atm, though :)
    </ModalText>
  </ScrollableDiv>
);

const ScrollableDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  height: 100%;
  padding-right: 16px;
  margin-top: 16px;
`;

const FloatingScrollDownButton = styled(FaArrowDown)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-size: 24px;
  color: ${PRIMARY_COLOR};
  cursor: pointer;
`;

export default NotificationModal;
