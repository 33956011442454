import React from 'react';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import styled from 'styled-components';
import GetSubscriptionModal from '../../Modals/TopMenuModals/GetSubscriptionModal';
import { OptionContainer } from './TopBarMenu';
import { MdOutlineStar } from 'react-icons/md';
import { GOLD_COLOR } from '../../../constants';
import { useGeneralContext } from '../../../context/GeneralContextProvider';

const SubscriptionOption: React.FC = () => {
  const { loggedInUser } = useLoggedInUserContext();

  const { goldModalOpen, setGoldModalOpen } = useGeneralContext();
  const handleOpenGoldModal = () => {
    setGoldModalOpen(true);
  };

  const isGold = loggedInUser?.type === 'gold';

  return (
    <>
      <OptionContainer onClick={handleOpenGoldModal}>
        <Button>
          <MdOutlineStar size={18} color={GOLD_COLOR} />
          {isGold ? 'Manage subscription' : 'Get Subscription'}
        </Button>
      </OptionContainer>
      <GetSubscriptionModal
        showModal={goldModalOpen}
        setShowModal={setGoldModalOpen}
      />
    </>
  );
};

const Button = styled.button`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;

  background-color: transparent;
  border: none;
`;

export default SubscriptionOption;
