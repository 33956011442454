/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components';
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  PRIMARY_TEXT_COLOR,
  LIGHTER_GREY_FOR_HOVER_EFFECT,
  PICARUS_PROFILE_URL,
} from '../../../constants';
import CreditsInPopup from './CreditsInPopup';
import SubscriptionOption from './SubscriptionOption';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import { LoginOption, LogoutOption } from './LoginAndLogoutOptions';
import useHandleClickOutside from './useHandleClickOutside';
import { useRef } from 'react';
import { FaCommentAlt, FaQuestion } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { LIBRARY_PATH, PROFILE_PATH } from '../../../pathNames';
import { BsPersonFill } from 'react-icons/bs';
import ClaimFreeCreditsOption from './ClaimFreeCreditsOption';
import { toast } from 'react-toastify';
import InstallAppButton from './InstallAppButton';
import { getIsOnDesktop } from '../../../styleHelpers';
import { MdOutlineCollections } from 'react-icons/md';
import { DiscordLogo, RedditLogo } from './SocialLogos';
import { isStandalone } from '../../helpers';

interface P {
  buttonRef: React.RefObject<HTMLButtonElement>;
  popupX: number;
  showPopup: boolean;
  closePopup: () => void;
}
const TopBarMenu = ({ buttonRef, popupX, showPopup, closePopup }: P) => {
  const { loggedInUser } = useLoggedInUserContext();
  const navigate = useNavigate();
  const popupRef = useRef(null);

  const showBuyCredits = true;

  const showSubscription = true;

  const isLoggedIn = !!loggedInUser;
  const showLogout = isLoggedIn;
  const showLogin = !isLoggedIn;


  const showInstallApp = !isStandalone() && !getIsOnDesktop();

  // show thin line if there is at least one option above it
  const showFirstThinLine = showBuyCredits || showSubscription;

  // event listener to close popup when clicking outside of it
  useHandleClickOutside([popupRef, buttonRef], closePopup);

  const openReddit = () => {
    window.open('https://www.reddit.com/r/OnlyFakes_app/', '_blank');
  };

  const openDiscord = () => {
    window.open('https://discord.gg/eCkrtmb79j', '_blank');
  };

  const openProfile = () => {
    navigate(PROFILE_PATH);
    closePopup();
  };

  const openSupport = () => {
    toast.info(
      "Please email hello@UncensoredAI.io and we will help you out OR check out Discord/Reddit for the community's help 🤗",
      { autoClose: 7000 }
    );
  };

  const emojiToRender = '😇';
  const TextToRender = 'Go to SFW Site';

  const navToSisterSite = () => {
    alert('Use your OnlyFakes credentials to log in.');
    window.location.href = PICARUS_PROFILE_URL;
  };

  const openGiveFeedback = () => {
    window.open('https://only-fakes.canny.io/', '_blank');
  };

  const handleNavToLibrary = () => {
    navigate(LIBRARY_PATH);
    closePopup();
  };

  const HIDE_SFW_OPTION = true;

  return (
    <Popup
      ref={popupRef}
      popupX={popupX}
      className={showPopup ? 'visible' : ''}
    >
      {isLoggedIn && (
        <>
          <OptionContainer onClick={openProfile}>
            <ButtonWithIcon>
              <BsPersonFill size={18} />
              Profile
            </ButtonWithIcon>
          </OptionContainer>
          <ThinLine />
        </>
      )}

      {showSubscription && <SubscriptionOption />}

      {showBuyCredits && <CreditsInPopup />}

      {showBuyCredits && <ClaimFreeCreditsOption />}

      {showFirstThinLine && <ThinLine />}

      {showInstallApp && <InstallAppButton />}

      <OptionContainer onClick={openGiveFeedback}>
        <ButtonWithIcon>
          <FaCommentAlt size={20} />
          Give feedback
        </ButtonWithIcon>
      </OptionContainer>

      <OptionContainer onClick={openSupport}>
        <ButtonWithIcon>
          <FaQuestion size={20} />
          Support
        </ButtonWithIcon>
      </OptionContainer>

      <ThinLine />

      <OptionContainer>
        <SocialsContainer>
          Community: 
          <DiscordLogo />
          <RedditLogo />
        </SocialsContainer>
      </OptionContainer>

      {!HIDE_SFW_OPTION && (
        <OptionContainer onClick={navToSisterSite}>
          <SfwOptionButtonContainer>
            {emojiToRender}
            <SmallEmptySpace />
            {TextToRender}
          </SfwOptionButtonContainer>
        </OptionContainer>
      )}

      <ThinLine />

      {showLogout && <LogoutOption closePopup={closePopup} />}

      {showLogin && <LoginOption closePopup={closePopup} />}
    </Popup>
  );
};

const ThinLine = styled.div`
  height: 0.5px;
  width: 90%;
  background-color: ${BORDER_COLOR};

  margin-left: 8px;

  margin-top: 12px;
  margin-bottom: 12px;
`;

const Popup = styled.div<{ popupX: number }>`
  position: absolute;
  top: 54px;
  left: ${({ popupX }) => popupX - 300}px;
  @media (max-width: 568px) {
    left: ${({ popupX }) => popupX - 240}px;
  }

  background-color: ${BACKGROUND_COLOR};
  border: 0.1px solid ${BORDER_COLOR};
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);
  z-index: 100;

  width: 240px;

  opacity: 0;
  visibility: hidden; // Use visibility instead of display
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.4s ease, visibility 0s linear 0.4s; // Add transition for visibility
  &.visible {
    opacity: 1;
    visibility: visible; // Make element visible
    transform: translateY(0);
    transition-delay: 0s; // Remove delay when becoming visible
  }
`;

export const OptionContainer = styled.div`
  padding: 6px;
  padding-bottom: 10px;
  color: ${PRIMARY_TEXT_COLOR};
  cursor: pointer;

  transition: background-color 0.2s;
  &:hover {
    // pointer mouse:
    cursor: pointer;
    background-color: ${LIGHTER_GREY_FOR_HOVER_EFFECT};
  }
  display: flex;

  button {
    cursor: pointer;
  }
`;

export const ButtonWithIcon = styled.button`
  background-color: transparent;
  align-items: center;
  display: flex;
  gap: 8px;
  font-size: 14px;

  border-radius: 8px;

  border: none;
`;

const SfwOptionButtonContainer = styled(ButtonWithIcon)`
  gap: 0;
  margin-left: 3px;
`;

const SmallEmptySpace = styled.div`
  width: 10px;
`;

const SocialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  margin-left: 8px;
  font-weight: 600;
  font-size: 14px;

  div {
    cursor: pointer;
  }
`;

export default TopBarMenu;
