import styled from 'styled-components';
import { CloseButtonInModal } from '../../../common/CloseButtonInModal';
import TransitioningModal from '../../TransitioningModal';
import { useChatContext } from '../../../../context/ChatContextProvider';
import { desktopMediaQuery } from '../../../../styleHelpers';
import { DEFAULT_BOT_NAME } from '../../../../chatConstants';

interface ShowChatImagesModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

const ShowChatImagesModal = ({ show, setShow }: ShowChatImagesModalProps) => {
  const { chats, activeChatId } = useChatContext();
  const activeChat = chats.find((chat) => chat.id === activeChatId);
  const currentImage = activeChat?.activeImage;
  const botName = activeChat?.botName || DEFAULT_BOT_NAME;
  
  const handleClose = () => setShow(false);

  return (
    <Modal
      isOpen={show}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 100,
        },
        content: {
          position: 'relative',
          padding: '16px',
          backgroundColor: 'rgba(0,0,  0, 0.8)', 
          border: 'none',
          width: '90vw',
          maxWidth: '800px',
          height: '90vh',
          margin: 'auto',
          marginTop: '1vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <CloseButtonInModal onClick={handleClose} />
      <BotNameTitle>{botName}</BotNameTitle>
      <ImageContainer>
        <ResponsiveImage src={currentImage} alt="Character" />
      </ImageContainer>
    </Modal>
  );
};

const Modal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
  z-index: 3;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 16px;
`;

const ResponsiveImage = styled.img`
  max-height: 80vh;
  max-width: 90%;
  width: auto;
  height: auto;
  object-fit: contain;
  border-radius: 8px;

  ${desktopMediaQuery} {
    max-height: 85vh;
    max-width: 95%;
  }
`;

const BotNameTitle = styled.h2`
  font-size: 24px;
  margin: 8px 0 2px 0;
  text-align: center;
  color: inherit;
  
  ${desktopMediaQuery} {
    font-size: 28px;
  }
`;

export default ShowChatImagesModal; 