import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Modal from '../TransitioningModal';
import { StyledButton } from '../../common/StyledButton';
import { useModalsContext } from '../../../context/ModalsContextProvider';
import { BACKGROUND_COLOR } from '../../../constants';
import SingleLineInputField from '../../../pages/ChatPage/SingleLineInputField';
import { CloseButtonInModal } from '../../common/CloseButtonInModal';
import PreOptionsSection from './PreOptionsSection';

interface PromptModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const PromptModal = ({ showModal, setShowModal }: PromptModalProps) => {
  const [input, setInput] = useState('');
  const { modalMessage, onConfirmCallback, closePromptModal, preOptions } =
    useModalsContext();
  const preOptionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showModal && preOptionsRef.current) {
      preOptionsRef.current.scrollTo({ left: 0, behavior: 'auto' });
    }
  }, [showModal, preOptions]);

  const handleConfirm = () => {
    onConfirmCallback?.(input);
    setInput('');
    closePromptModal();
  };

  const handleClose = () => {
    setShowModal(false);
    closePromptModal();
  };

  return (
    <Modal
      isOpen={showModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 100,
        },
        content: {
          position: 'relative',
          backgroundColor: BACKGROUND_COLOR,
          padding: '16px',
          width: '90vw',
          maxWidth: '400px',
          height: 'auto',
          margin: 'auto',
          marginTop: '20vh',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <ModalContent>
        <CloseButtonInModal onClick={handleClose} />
        <Message>{modalMessage}</Message>
        <InputContainer
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleConfirm();
            }
          }}
        >
          <SingleLineInputField
            hasBorder
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Write here..."
          />
        </InputContainer>
        {preOptions && preOptions.length > 0 && (
          <PreOptionsSection
            preOptions={preOptions}
            setInput={setInput}
            preOptionsRef={preOptionsRef}
          />
        )}
        <ButtonContainer>
          <StyledButton onClick={handleConfirm}>Ok</StyledButton>
        </ButtonContainer>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  min-width: 300px;
`;

const Message = styled.p`
  margin: 0;
  font-size: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
`;

const InputContainer = styled.div`
  width: 100%;
  margin-left: -16px;
`;

export default PromptModal;
