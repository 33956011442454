import { useModalsContext } from "../../../context/ModalsContextProvider";

interface PreOption {
  name: string;
  content: string;
}

const useModalPrompt = () => {
  const { openPromptModal, closePromptModal } = useModalsContext();

  return (message: string, preOptions?: PreOption[]) => {
    return new Promise<string | null>((resolve) => {
      openPromptModal(message, (inputValue) => {
        resolve(inputValue);  // This resolves the Promise with user input
        closePromptModal();
      }, preOptions);
    });
  };
};

export default useModalPrompt;