import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import { BASE_URL, PREVIEW_URL_FOR_FUNCTIONS, previewBaseBaseUrl } from './constants';
import useHandleGatherReferralId from './hooks/userHandling/useHandleGatherReferralId';
import ErrorBoundary from './components/common/ErrorBoundary';

export const isLocalhost = window.location.href.includes('localhost');
export const isPreviewing = window.location.href.includes('deploy-preview');
export const isInNetlify = false;

export const SITE_BASE_URL = isLocalhost
  ? 'http://localhost:3000'
  : isPreviewing
  ? previewBaseBaseUrl
  : BASE_URL;

export const getBaseUrlForFetching = () => {
  if (isLocalhost) {
    return 'http://localhost:8080';
  }
  if (isPreviewing) {
    return PREVIEW_URL_FOR_FUNCTIONS;
  }
  if (isInNetlify) {
    return 'https://uncensoredAI.netlify.app/.netlify/functions';
  }
  return `${BASE_URL}/.netlify/functions`;
};

/**
 * This is called from index.tsx and is the main entry point of the app
 */
function App() {
  useHandleGatherReferralId();

  return (
    <Router>
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </Router>
  );
}

export default App;
