import { createContext, useContext, useState } from "react";
import { GenericSetState, LoggedInUser } from "../types";

interface ILoggedInUserContext {
  loggedInUser: LoggedInUser | null;
  setLoggedInUser: GenericSetState<LoggedInUser | null>;

  isLoadingLogin: boolean;
  setIsLoadingLogin: GenericSetState<boolean>;

  // this is a helpers used specifically for useSendMessage
  userToken: string | null;
  setUserToken: GenericSetState<string | null>;
}

const defaultState = {
  loggedInUser: null,
  setLoggedInUser: undefined as unknown as GenericSetState<LoggedInUser | null>,

  isLoadingLogin: false,
  setIsLoadingLogin: undefined as unknown as GenericSetState<boolean>,

  userToken: null,
  setUserToken: undefined as unknown as GenericSetState<string | null>,
};

const LoggedInUserContext = createContext<ILoggedInUserContext>(defaultState);

export const useLoggedInUserContext = () => useContext(LoggedInUserContext);

// @ts-ignore children does actually exist, todo figure types?
const LoggedInUserContextProvider = ({ children }) => {
  const [loggedInUser, setLoggedInUser] = useState<LoggedInUser | null>(null);
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [userToken, setUserToken] = useState<string | null>(null);

  return (
    <LoggedInUserContext.Provider
      value={{
        loggedInUser,
        setLoggedInUser,
      
        isLoadingLogin,
        setIsLoadingLogin,

        userToken,
        setUserToken,
      }}
    >
      {children}
    </LoggedInUserContext.Provider>
  );
};

export default LoggedInUserContextProvider;
