// <ImageTitle title={image?.title} />
import React from 'react';
import styled from 'styled-components';
import { PRIMARY_TEXT_COLOR } from '../../../constants';
import { desktopMediaQuery } from '../../../styleHelpers';

interface ImageTitleProps {
  title: string | undefined;
}

const ImageTitle: React.FC<ImageTitleProps> = ({ title }) => {
  if (!title || title === 'Untitled') return null;
  return <Title>{title}</Title>;
};

const Title = styled.div`
  text-align: start;
  color: ${PRIMARY_TEXT_COLOR};
  width: 90%;

  font-weight: bold;

  margin-bottom: 2px;

  ${desktopMediaQuery} {
    text-align: center;
    font-size: 22px;
  }
`;
export default ImageTitle;
