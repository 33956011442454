import styled from 'styled-components';
import { GOLD_COLOR, PRIMARY_TEXT_COLOR } from '../../../constants';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import { GenericSetState, LoggedInUser } from '../../../types';
import { StyledButton } from '../../common/StyledButton';
import { CloseButtonInModal } from '../../common/CloseButtonInModal';
import {
  BenefitListItem,
  ContainerPaymentModal,
  LineInBuyModal,
  Modal,
  ModalText,
  paymentModalContentStylesMobile,
  paymentModalOverlayStyles,
} from './PaymentCommonComponents';
import { FaShoppingCart } from 'react-icons/fa';
import useCreateRedirectUrlForCentrobill from '../../../hooks/useCreateRedirectUrlForCentrobill';
import LoadingIndicator from '../../common/LoadingIndicator';
import { useState } from 'react';

interface I {
  showModal: boolean;
  setShowModal: GenericSetState<boolean>;
}

const IS_FIRST_MONTH_DISCOUNT = false;
const GetSubscriptionModal = ({ showModal, setShowModal }: I) => {
  const { loggedInUser, setLoggedInUser } = useLoggedInUserContext();
  const [loadingRedirect, setLoadingRedirect] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const createUrlToRedirectForCentrobill = useCreateRedirectUrlForCentrobill();

  const handleRedirectToSubscription = async () => {
    setLoadingRedirect(true);
    const packageType = IS_FIRST_MONTH_DISCOUNT
      ? 'firstMonthDiscountGoldSubscription'
      : 'goldSubscription';
    const urlToRedirect = await createUrlToRedirectForCentrobill(packageType);

    if (urlToRedirect) {
      window.location.href = urlToRedirect;
    } else {
      alert('Something went wrong, please try again later');
    }
    setLoadingRedirect(false);
  };

  return (
    <Modal
      isOpen={showModal}
      style={{
        overlay: paymentModalOverlayStyles,
        content: paymentModalContentStylesMobile,
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <ContainerPaymentModal inSubscriptionModal>
        <CloseButtonInModal onClick={handleClose}>X</CloseButtonInModal>
        <TitleText>Gold Subscription</TitleText>
        {loggedInUser?.type === 'gold' ? (
          <ManageExistingSub
            loggedInUser={loggedInUser}
            setLoggedInUser={setLoggedInUser}
            handleClose={handleClose}
          />
        ) : (
          <GetGoldContent
            handleGetGold={handleRedirectToSubscription}
            isLoading={loadingRedirect}
          />
        )}
      </ContainerPaymentModal>
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GetGoldContent = ({
  handleGetGold,
  isLoading,
}: {
  handleGetGold: () => void;
  isLoading: boolean;
}) => (
  <GetGoldContentContainer>
    <ModalText>
      <BenefitListItem>🎨 Unlimited Turbo Generations</BenefitListItem>
      <BenefitListItem>💬 Unlimited Chat Messages</BenefitListItem>
      <BenefitListItem>🔐 Save & Organize 2000+ Images</BenefitListItem>
      <BenefitListItem>⭐ Exclusive Gold Badge</BenefitListItem>
      <BenefitListItem>⚡ Priority Customer Support</BenefitListItem>
      <LineInBuyModal />
      <PricingText />
      <ExtraInfo />
    </ModalText>
    <GetGoldButton onClick={handleGetGold} disabled={isLoading}>
      {isLoading ? (
        <LoadingIndicator differentBgColor />
      ) : (
        <>
          <FaShoppingCart color={GOLD_COLOR} />
          Start Subscription
        </>
      )}
    </GetGoldButton>
  </GetGoldContentContainer>
);
/*
  <GetGoldContentContainer>
    <ModalText>
      Buying subscriptions is temporarily offline. Buy credits to enjoy fast
      image generation
    </ModalText>
  </GetGoldContentContainer>

*/

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PopularText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
  font-size: 16px;
  color: ${PRIMARY_TEXT_COLOR};
  font-weight: bold;

  margin-left: 12px;
`;

const GetGoldContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const PricingText = () => (
  <div
    style={{
      flexDirection: 'column',
      textAlign: 'center',
    }}
  >
    {IS_FIRST_MONTH_DISCOUNT ? (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
          }}
        >
          <p
            style={{
              fontSize: '24px',
              fontWeight: 'bold',
              textDecoration: 'line-through',
              opacity: 0.7,
            }}
          >
            $20
          </p>
          <p
            style={{
              fontSize: '28px',
              fontWeight: 'bold',
              color: GOLD_COLOR,
            }}
          >
            $10
          </p>
          <p>first month</p>
        </div>
        <p
          style={{ fontSize: '16px', marginTop: '-16px', marginBottom: '24px' }}
        >
          then $20/month. Cancel anytime.
        </p>
      </>
    ) : (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '8px',
        }}
      >
        <p
          style={{
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          $20
        </p>
        <p>/ month</p>
      </div>
    )}
  </div>
);

const ExtraInfo = () => (
  <div
    style={{
      fontSize: '14px',
      paddingLeft: 32,
      paddingRight: 32,
      marginTop: -16,
      marginBottom: -16,
    }}
  >
    <i>(+ taxes)</i>
  </div>
);

const ManageExistingSub = ({
  loggedInUser,
}: {
  loggedInUser: LoggedInUser;
  setLoggedInUser: GenericSetState<LoggedInUser | null>;
  handleClose: () => void;
}) => {
  const getSubscriptionIsValidUntil = () => {
    if (!loggedInUser.lastPaymentDate) return null;
    const lastPaymentDate = new Date(loggedInUser.lastPaymentDate);
    const date = new Date(
      lastPaymentDate.setMonth(lastPaymentDate.getMonth() + 1),
    );
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const subscriptionIsValidUntil = getSubscriptionIsValidUntil();

  return (
    <>
      <ModalText>
        <p>You are a Gold user! 🎉</p>
        <p>
          In case of refunds or issues, contact us at{' '}
          <a href="mailto:hello@uncensoredAI.io">hello@uncensoredAI.io</a>
        </p>
        <div
          style={{
            fontSize: '16px',
          }}
        >
          <>
            Your subscription will be automatically renewed on{' '}
            <b>{subscriptionIsValidUntil}</b>
            <br />
            <br />
            To cancel it, visit this link: <br />{' '}
            <a
              href="https://centrohelp.eu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cancel Subscription
            </a>
            <br />
            In case of any issues, please contact us at hello@TheUncensored.AI
          </>
        </div>
      </ModalText>
    </>
  );
};

export const GetGoldButton = styled(StyledButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-color: ${GOLD_COLOR};
  box-shadow: 2px 2px 4px ${GOLD_COLOR};
`;
const TitleText = styled(ModalText)`
  font-size: 22px;
  margin-bottom: 8px;
`;

export default GetSubscriptionModal;
