import React, { useEffect, useState } from 'react';
import NavigationBar from './components/NavigationBar/NavigationBar';
import TopBar, { getShouldHideTopBar } from './components/common/TopBar/TopBar';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useCookies } from 'react-cookie';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { fadeIn } from './components/ImageStuff/animations';
import ConfirmEmailModal from './components/Modals/ConfirmEmailModal/ConfirmEmailModal';
import ServerIssuesModal from './components/Modals/NewEnginesInfoModal';
import MagicModeModal from './components/Modals/NotificationModals/MagicModeModal';
import NotificationModal from './components/Modals/NotificationModals/NotificationModal';
import RequireRefreshModal from './components/Modals/RequireRefreshModal';
import ServiceDownModal from './components/Modals/ServiceDownModal';
import TermsModal from './components/Modals/TermsModal';
import { StyledButton } from './components/common/StyledButton';
import {
  BORDER_COLOR,
  LIGHTER_BG_FOR_TOAST,
  PRIMARY_TEXT_COLOR,
} from './constants';
import { useGeneralContext } from './context/GeneralContextProvider';
import { useLoggedInUserContext } from './context/LoggedInUserContextProvider';
import { useSessionCheck } from './hooks/misc/useSessionCheck';
import useHandleGetUser from './hooks/userHandling/useHandleGetUser';
import useHandleIfUserIsVeryFirstTimeUser from './hooks/userHandling/useHandleIfUserIsVeryFirstTimeUser';
import {
  getImagesFromLocalStorage,
  setImagesToLocalStorage,
} from './localStorage/imageStorage';
import { LoggedInUser } from './types';
import Cookies from './utils/Cookies';
import { desktopMediaQuery } from './styleHelpers';
import CreateNewPostModal from './components/Modals/CreateNewPostModal/CreateNewPostModal';
import AuthModal from './components/Modals/AuthModal.tsx/AuthModal';
import { useModalsContext } from './context/ModalsContextProvider';
import HintToRegisterModal from './components/Modals/HintToRegisterModal';
import PromptModal from './components/Modals/PromptModal/PromptModal';
import useSetUserCookie from './hooks/userHandling/useSetUserCookie';
import NotificationBar from './pages/HomePage/HomePageNotification/NotificationBar';
import GoToTheNewSiteModal from './components/Modals/NotificationModals/GoToTheNewSiteModal';

/**
 * If logged in user is gold, and the cookie is not gold, remove the cookie
 * Fixes a weird bug
 */
const isCookieIncorrect = (token: string, loggedInUser: LoggedInUser) => {
  const decodedToken = JSON.parse(atob(token.split('.')[1]));

  if (loggedInUser?.type === 'gold' && decodedToken?.type !== 'gold') {
    return true;
  }
  return false;
};

/**
 * This is where the majory of the app itself lives
 * See Routes.tsx for the routes
 */
const MainApp: React.FC = () => {
  const [showServiceDown, setShowServiceDown] = useState(false);
  const [magicModeNotification, setMagicModeNotification] = useState(false);
  const [showNewNotificationModal, setShowNewNotificationModal] =
    useState(false);

  const { loggedInUser } = useLoggedInUserContext();
  const {
    showConfirmEmailModal,
    setShowConfirmEmailModal,
    showCreateNewPostModal,
    setShowCreateNewPostModal,
    setHasVisitedMainApp,
  } = useGeneralContext();

  const {
    isAuthModalOpen,
    setIsAuthModalOpen,
    showHintToRegisterModal,
    setShowHintToRegisterModal,
    isPromptOpen: showPromptModal,
    closePromptModal: setShowPromptModal,
  } = useModalsContext();

  const [cookies] = useCookies(['token']);

  const handleGetUser = useHandleGetUser();
  const requiresRefresh = useSessionCheck();

  //useHandleAds();

  const [showServerIssues, setShowServerIssues] = useState(false);
  // Check from local storage if user has seen the modal
  /*
  useEffect(() => {
    const hasSeenServerIssues = localStorage.hasSeenServerIssues;
    if (hasSeenServerIssues === "true") return;

    setShowServerIssues(true);
    localStorage.hasSeenServerIssues = "true";
  }, [localStorage]);
  */

  useHandleIfUserIsVeryFirstTimeUser();

  const [showFirstTimeModal, setShowFirstTimeModal] = useState(false);

  const handleAcceptAndClose = () => {
    localStorage.setItem('acceptedTerms', 'true');
    setShowFirstTimeModal(false);
  };

  const getHasAcceptedTerms = () => {
    const hasAcceptedTerms = localStorage.getItem('acceptedTerms');
    if (!hasAcceptedTerms) {
      return false;
    }
    return true;
  };

  const isCrawler = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const crawlers = [
      'googlebot',
      'bingbot',
      'slurp',
      'duckduckbot',
      'baiduspider',
      'yandexbot',
    ];
    return crawlers.some((crawler) => userAgent.includes(crawler));
  };

  useEffect(() => {
    setHasVisitedMainApp(true);
    const hasAcceptedTerms = getHasAcceptedTerms();
    const crawlerDetected = isCrawler();

    if (!hasAcceptedTerms && !crawlerDetected) {
      setShowFirstTimeModal(true);
    }
  }, []);

  useEffect(() => {
    // a hack to make sure useWhenMountsDisableBodyScroll works and doens't lock this screen
    document.body.style.overflow = 'unset';
  }, []);

  const setUserCookie = useSetUserCookie();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromParams = urlParams.get('userToken');
    if (tokenFromParams) {
      setUserCookie(tokenFromParams);
      handleGetUser(tokenFromParams).then(() => {
        window.location.href = `https://uncensoredai.io/app`;
      });
    }
  }, []);

  useEffect(() => {
    const token = cookies.token;
    if (token) {
      // TODO: make this async maybe
      handleGetUser(token);

      if (!loggedInUser) {
        return;
      }

      // TODO: probably not needed anymore?
      if (isCookieIncorrect(token, loggedInUser)) {
        Cookies().clearToken();
        alert('Error happened with your login 😭 Please login again.');
        setIsAuthModalOpen(true);
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    removeOldImagesFromLocalStorage();
  }, []);

  const removeOldImagesFromLocalStorage = () => {
    const storageContent = getImagesFromLocalStorage();
    if (!storageContent) return;
    const isIosSafari =
      /iP(hone|od|ad)/.test(navigator.userAgent) &&
      navigator.userAgent.includes('Safari') &&
      !navigator.userAgent.includes('Chrome');
    const howManyToKeep = isIosSafari ? 170 : 400;
    const newContent = storageContent.slice(0, howManyToKeep);
    setImagesToLocalStorage(newContent);
  };

  // Check from local storage is user has seen the modal
  /*
  useEffect(() => {
    const update = async () => {
      const hasSeenThisModal = localStorage.hasSeenMagicModeModal;
      if (hasSeenThisModal === 'true') return;
      setMagicModeNotification(true);

      localStorage.hasSeenMagicModeModal = 'true';
    };
    update();
  }, [loggedInUser]);
  */

 /*
 // Check if notification modal should be shown (every 3 days)
  const ITEM_NAME_FOR_LAST_SHOWN_DATE = 'lastShownFeb25DiscountNotification';
  useEffect(() => {
    const lastShownDate = localStorage.getItem(ITEM_NAME_FOR_LAST_SHOWN_DATE);
    const twoDaysInMs = 2 * 24 * 60 * 60 * 1000;
    const shouldShowAgain =
      !lastShownDate || Date.now() - parseInt(lastShownDate) > twoDaysInMs;

    if (!shouldShowAgain) {
      return;
    }

    if (!loggedInUser || loggedInUser.type === 'gold') {
      return;
    }

    setShowNewNotificationModal(true);
    localStorage.setItem(ITEM_NAME_FOR_LAST_SHOWN_DATE, Date.now().toString());
  }, [loggedInUser]);
  */

  // show go to the new site modal every day once
  const [showGoToTheNewSiteModal, setShowGoToTheNewSiteModal] = useState(false);
  const ITEM_NAME_FOR_LAST_SHOWN_DATE = 'lastShownGoToTheNewSiteModal';
  useEffect(() => {
    const lastShownDate = localStorage.getItem(ITEM_NAME_FOR_LAST_SHOWN_DATE);
    const dayInMs = 1 * 24 * 60 * 60 * 1000;
    const shouldShowAgain =
      !lastShownDate || Date.now() - parseInt(lastShownDate) > dayInMs;

    if (!shouldShowAgain) {
      return;
    }

    localStorage.setItem(ITEM_NAME_FOR_LAST_SHOWN_DATE, Date.now().toString());
    setShowGoToTheNewSiteModal(true);
  }, []);

  /*
  // Check from local storage is user has seen the socials notification modal
  const [showTrySocialsModal, setShowTrySocialsModal] = useState(false);
  useEffect(() => {
    if (!loggedInUser) return;

    const hasSeenThisModal = localStorage.hasSeenTrySocialsModal;
    if (hasSeenThisModal === 'true') return;

    setShowTrySocialsModal(true);
    localStorage.hasSeenTrySocialsModal = 'true';
  }, [loggedInUser]);
  */

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isApproved = searchParams.get('is_approved') === '1';
  const paymentStatus = searchParams.get('payment');
  const isGoldSubscription =
    searchParams.get('packageType') === 'goldSubscription';

  useEffect(() => {
    if (paymentStatus !== 'success') return;

    if (isApproved) {
      alert(
        isGoldSubscription
          ? "Woohoo payment successful! 🎉 If you don't see your new Subscription status, please wait just a while and refresh the page"
          : "Woohoo payment successful! 🎉 If you don't see your credits, please refresh the page",
      );
    } else {
      alert(
        'The payment is still pending, or it might have failed. If this takes longer than expected, please contact hello@uncensoredAI.io',
      );
    }

    // Clean up URL parameters
    const cleanUrl = window.location.pathname;
    window.history.replaceState({}, '', cleanUrl);
  }, [isApproved, isGoldSubscription, paymentStatus]);

  // empty space is there to make sure the top bar doesn't cover the content
  const [shouldShowEmptySpace, setShouldShowEmptySpace] = useState(true);
  useEffect(() => {
    const shouldHideTopBar = getShouldHideTopBar();
    setShouldShowEmptySpace(!shouldHideTopBar);
  }, [location]);

  return (
    <MainAppContainer>
      <StyledToastContainer
        position="top-center"
        hideProgressBar
        closeButton={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        draggablePercent={50}
        autoClose={TIME_TO_CLOSE_TOAST}
      />
      <ServerIssuesModal
        showModal={showServerIssues}
        setShowModal={setShowServerIssues}
      />
      <RequireRefreshModal showModal={requiresRefresh} />
      <MagicModeModal
        showModal={magicModeNotification}
        setShowModal={setMagicModeNotification}
      />
      <NotificationModal
        showModal={showNewNotificationModal}
        setShowModal={setShowNewNotificationModal}
      />

      <GoToTheNewSiteModal
        showModal={showGoToTheNewSiteModal}
        setShowModal={setShowGoToTheNewSiteModal}
      />

      <ServiceDownModal
        showModal={showServiceDown}
        setShowModal={setShowServiceDown}
      />
      <ConfirmEmailModal
        showModal={showConfirmEmailModal}
        setShowModal={setShowConfirmEmailModal}
      />

      <TermsModal
        showModal={showFirstTimeModal}
        handleAcceptAndClose={handleAcceptAndClose}
      />

      <CreateNewPostModal
        showModal={showCreateNewPostModal}
        setShowModal={setShowCreateNewPostModal}
      />

      <AuthModal
        showModal={isAuthModalOpen}
        setShowModal={setIsAuthModalOpen}
      />

      <HintToRegisterModal
        showModal={showHintToRegisterModal}
        setShowModal={setShowHintToRegisterModal}
      />

      <PromptModal
        showModal={showPromptModal}
        setShowModal={setShowPromptModal}
      />

      <TopBar />
      {shouldShowEmptySpace && <EmptySpace />}
      <NotificationBar />

      <Outlet />
      <NavigationBar />
    </MainAppContainer>
  );
};

// This empty space is so the TopBar doesn't cover the content
const EmptySpace = styled.div`
  height: 1px;
  margin-top: 76px;

  ${desktopMediaQuery} {
    margin-top: 86px;
  }
`;

export const TIME_TO_CLOSE_TOAST = 2500;
// Default toast styles, "save folder" toast does overwrite this though
const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    border-radius: 8px;
    background: ${LIGHTER_BG_FOR_TOAST};
    box-shadow: 2px 5px 14px rgba(0, 0, 0, 0.9);
    color: ${PRIMARY_TEXT_COLOR};
    padding: 8px;
    border: 2px solid black;
  }

  .Toastify__toast.SaveToast {
    background: transparent;
    box-shadow: none;
    border: none;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EnableFullscreenButton = styled(StyledButton)`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  margin-top: -20px;
  gap: 16px;

  font-size: 14px;

  max-width: 60vw;

  border: 1px solid ${BORDER_COLOR};
`;

const MainAppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100vh;
  @media (min-width: 768px) {
    max-height: 90vh;
  }
  // TODO: why this has to be here to center eveything?
  max-width: 96vw;
  align-self: center;
  justify-self: center;
  padding-left: 1px;
  padding-right: 1px;

  // This causes the animation when the app is first loaded
  animation: ${fadeIn} 2s ease-in-out;
`;

export default MainApp;
