import { PRIMARY_COLOR } from './constants';

export const ONBOARDING_TYPE_MSG = 'onboarding';
export const HIDDEN_TYPE_MSG = 'hidden';
export const FIRST_HIDDEN_MESSAGE = 'hidden-first';

export const assistantRole = 'assistant';
export const userRole = 'user';
export const systemRole = 'system';

export const GIRLFRIEND_PERSONA =
  'Girlfriend, cute, loving, caring, sexy, flirty';
export const HORNY_PERSONA =
  'She is Horny, explicit, a girlfriend, always ready to fuck or sexting. Very flirty';
export const JERK_OFF_INSTRUCTIONS =
  "When asked for Jerk-off Instructions, they provide step by step instructions, slowly building up tension. Show don't tell";
export const DEFAULT_SCENARIO =
  'Flirty, friendly, fun chat that follows users wishes';

export const DEFAULT_BOT_NAME = 'Sarah';

export const BOT_MESSAGE_COLOR = PRIMARY_COLOR; //"#d707a4"
export const BOT_MESSAGE_COLOR_DIMMED = "#E8E8E8E6"
export const USER_MESSAGE_COLOR = '#303134'; 
export const USER_MESSAGE_COLOR_DIMMED = "#E8E8E8E6"
export const DEFAULT_IMAGE_TO_USE = '/example1.jpg';
