export const TEXT_PROMPT_BOT_NAME = 'Select a name for the bot';
export const BOT_NAME_OPTIONS = [
  { name: 'Sarah', content: 'Sarah' },
  { name: 'Jane', content: 'Jane' },
  { name: 'Alice', content: 'Alice' },
  { name: 'Aisha', content: 'Aisha' },
  { name: 'Mei', content: 'Mei' },
  { name: 'Priya', content: 'Priya' },
  { name: 'Sofia', content: 'Sofia' },
  { name: 'Emily', content: 'Emily' },
  { name: 'Olivia', content: 'Olivia' },
  { name: 'Mia', content: 'Mia' },
  { name: 'Isabella', content: 'Isabella' },
  { name: 'Charlotte', content: 'Charlotte' },
  { name: 'Amelia', content: 'Amelia' },
  { name: 'Harper', content: 'Harper' },
  { name: 'Evelyn', content: 'Evelyn' },
  { name: 'Abigail', content: 'Abigail' },
  { name: 'Ella', content: 'Ella' },
  { name: 'Grace', content: 'Grace' },
  { name: 'Chloe', content: 'Chloe' },
  { name: 'Lily', content: 'Lily' },
  { name: 'Scarlett', content: 'Scarlett' },
  { name: 'Aria', content: 'Aria' },
  { name: 'Avery', content: 'Avery' },
  { name: 'Sofia', content: 'Sofia' },
  { name: 'Zoey', content: 'Zoey' },
  { name: 'Nora', content: 'Nora' },
  { name: 'Riley', content: 'Riley' },
  { name: 'Layla', content: 'Layla' },
];

export const TEXT_PROMPT_CHAT_SCENARIO =
  'Choose scenario & persona (used as AI context)';
export const TEXT_PROMPT_CHAT_SCENARIO_OPTIONS = [
  {
    name: 'Girlfriend',
    content:
      "Assistant acts as user's girlfriend: cute, loving, caring, sexy, flirty",
  },
  {
    name: 'Horny',
    content:
      'Assistant is very horny and explicit: always ready to fuck or sext. Very flirty',
  },
  {
    name: 'Jerk-off instructions',
    content:
      "When asked for Jerk-off Instructions, assistant provides step by step instructions, slowly building up the tension. Show don't tell",
  },
  {
    name: 'Dominatrix',
    content:
      'Assistant assumes dominant role: gives commands, uses humiliation play, controls the scene. Talks authoritatively',
  },
  {
    name: 'Cheating Fantasy',
    content:
      "Assistant pretends to be someone else's partner engaging in secret affair. Includes guilty pleasure elements",
  },
  {
    name: 'Public Risky',
    content:
      'Simulates encounters in public spaces where risk of discovery creates excitement. Focuses on stealthy tension',
  },
];

export const TEXT_PROMPT_YOUR_NAME = 'Enter your name';
export const USER_NAME_OPTIONS = [
  { name: 'John', content: 'John' },
  { name: 'Mike', content: 'Mike' },
  { name: 'Alex', content: 'Alex' },
];
