import { RefObject } from 'react'
import styled from 'styled-components'
import { FiArrowLeftCircle, FiArrowRightCircle } from 'react-icons/fi'
import { BORDER_COLOR, HOVER_COLOR, LIGHT_GREY_FOR_TAG, PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from '../../../constants'
import { desktopMediaQuery } from '../../../styleHelpers'

interface PreOption {
  name: string
  content: string
}

interface PreOptionsSectionProps {
  preOptions: PreOption[]
  setInput: (value: string) => void
  preOptionsRef: RefObject<HTMLDivElement>
}

const PreOptionsSection = ({ preOptions, setInput, preOptionsRef }: PreOptionsSectionProps) => {
  const showArrows = window.innerWidth >= 1000

  const handleScroll = (direction: 'left' | 'right') => {
    const distance = 200
    const elem = preOptionsRef.current
    if (!elem) return

    const nextScroll = elem.scrollLeft + (direction === 'left' ? -distance : distance)
    elem.scrollTo({ left: nextScroll, behavior: 'smooth' })
  }

  return (
    <>
      <PreOptionsContainer ref={preOptionsRef}>
        {preOptions.map((option, index) => (
          <PreOptionButton
            key={index}
            onClick={() => setInput(option.content)}
          >
            {option.name}
          </PreOptionButton>
        ))}
      </PreOptionsContainer>
      <PreOptionsArrowsContainer>
        {showArrows && (
          <Arrows
            onClickLeft={() => handleScroll('left')}
            onClickRight={() => handleScroll('right')}
          />
        )}
      </PreOptionsArrowsContainer>
    </>
  )
}

const PreOptionsContainer = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  width: 111%;
  // stupid hack to make it be in right spot
  margin-left: -16px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
`

const PreOptionButton = styled.div`
  text-align: center;
  min-height: 20px;
  padding: 6px 10px;
  border-radius: 10px;
  font-size: 14px;
  background-color: ${LIGHT_GREY_FOR_TAG};
  color: ${PRIMARY_TEXT_COLOR};
  font-weight: bold;
  letter-spacing: -0.4px;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.3s;

  &:hover {
    border-color: ${PRIMARY_COLOR};
  }
`

interface ArrowsProps {
  onClickLeft: () => void
  onClickRight: () => void
}

const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 24px;
  gap: 8px;
`

const ArrowContainer = styled.div`
  display: flex;
  color: ${BORDER_COLOR};
  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      color: ${HOVER_COLOR};
    }
  }
`

const Arrows = ({ onClickLeft, onClickRight }: ArrowsProps) => (
  <ArrowsContainer>
    <ArrowContainer onClick={onClickLeft}>
      <FiArrowLeftCircle />
    </ArrowContainer>
    <ArrowContainer onClick={onClickRight}>
      <FiArrowRightCircle />
    </ArrowContainer>
  </ArrowsContainer>
)

const PreOptionsArrowsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`

export default PreOptionsSection 