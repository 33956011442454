import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import {
  BACKGROUND_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../../constants';
import { CloseButtonInModal } from '../../common/CloseButtonInModal';
import { StyledButton } from '../../common/StyledButton';
import TransitioningModal from '../TransitioningModal';
import { desktopMediaQuery } from '../../../styleHelpers';

interface GoToTheNewSiteModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const GoToTheNewSiteModal = ({ showModal, setShowModal }: GoToTheNewSiteModalProps) => {
  const [cookies] = useCookies(['token']);
  
  const handleClose = () => {
    setShowModal(false);
  };

  const handleVisitNewSite = () => {
    const token = cookies.token;
    window.location.href = token
      ? `https://TheUncensored.AI/app?userToken=${token}`
      : 'https://TheUncensored.AI/app';
  };

  return (
    <Modal
      isOpen={showModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 100,
        },
        content: {
          position: 'relative',
          backgroundColor: BACKGROUND_COLOR,
          padding: '20px',
          height: 'auto',
          width: '90vw',
          maxWidth: '500px',
          margin: 'auto',
          marginTop: '15vh',
        },
      }}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      onRequestClose={handleClose}
    >
      <CloseButtonInModal
        style={{
          position: 'absolute',
          top: '16px',
          right: '16px',
          color: PRIMARY_TEXT_COLOR,
        }}
        onClick={handleClose}
      />

      <ContentContainer>
        <TitleText>We're Moving! 🚀</TitleText>
        
        <ModalText>
          Due to requirements from our domain owner, we need to move to a new domain.
        </ModalText>
        
        <ModalText>
          Please click the button below to continue to our new site with new features. You will be automatically logged in.
        </ModalText>
        
        <RedirectButton onClick={handleVisitNewSite}>
          Go to TheUncensored.AI
        </RedirectButton>
      </ContentContainer>
    </Modal>
  );
};

const Modal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 5px;
`;

const TitleText = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${PRIMARY_TEXT_COLOR};
  text-align: center;
  margin-bottom: 20px;

  ${desktopMediaQuery} {
    font-size: 24px;
  }
`;

const ModalText = styled.div`
  font-size: 16px;
  color: ${PRIMARY_TEXT_COLOR};
  text-align: center;
  margin-bottom: 16px;
  max-width: 400px;
`;

const RedirectButton = styled(StyledButton)`
  margin-top: 10px;
  padding: 12px 24px;
  border: 1px solid ${PRIMARY_COLOR};
  font-weight: 600;
`;

export default GoToTheNewSiteModal; 