import styled from 'styled-components';
import {
  BACKGROUND_COLOR,
  DESKTOP_WIDTH,
  LIGHT_GREY_COLOR,
  PRIMARY_COLOR,
} from '../../constants';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageNames } from '../../types';

import {
  FEED_PATH,
  HOME_PATH,
  INPAINTING_PATH,
  LIBRARY_PATH,
  PROFILE_PATH,
  GENERATE_PATH,
  CHAT_CONVERSATION_PATH,
  CHAT_PATH,
} from '../../pathNames';
import {
  desktopMediaQuery,
  getIsOnDesktop,
  mobileMediaQuery,
} from '../../styleHelpers';
import { MdChat, MdOutlineCollections, MdSearch } from 'react-icons/md';
import { FaHome, FaMagic } from 'react-icons/fa';

const NavigationBar = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<PageNames>('home');

  const handleNavHome = (e: React.MouseEvent) => {
    e.preventDefault();
    setSelectedTab('home');
    navigate(HOME_PATH);
  };

  const handleNavCreate = (e: React.MouseEvent) => {
    e.preventDefault();
    setSelectedTab('create');
    navigate(GENERATE_PATH);
  };

  const handleNavFeed = (e: React.MouseEvent) => {
    e.preventDefault();
    setSelectedTab('explore');
    navigate(FEED_PATH);
  };

  const handleClick3 = (e: React.MouseEvent) => {
    e.preventDefault();
    setSelectedTab('library');
    navigate(LIBRARY_PATH);
  };

  const navToChat = (e: React.MouseEvent) => {
    e.preventDefault();
    setSelectedTab('chat');
    navigate(CHAT_CONVERSATION_PATH);
  };

  const path = window.location.pathname;
  useEffect(() => {
    if (path === HOME_PATH) {
      setSelectedTab('home');
    } else if (path.includes(GENERATE_PATH)) {
      setSelectedTab('create');
    } else if (path === FEED_PATH) {
      setSelectedTab('explore');
    } else if (path === LIBRARY_PATH) {
      setSelectedTab('library');
    } else if (path === PROFILE_PATH) {
      setSelectedTab('profile');
    } else if (path === INPAINTING_PATH) {
      setSelectedTab('create');
    } else if (path.includes(CHAT_PATH)) {
      setSelectedTab('chat');
    }
  }, [path]);

  const isOnDesktop = getIsOnDesktop();
  const shouldHideNavigationbarSlightly =
    !isOnDesktop && window.location.href.includes('chat/conversation');

  const isHomeSelected = selectedTab === 'home';
  const isCreateSelected = selectedTab === 'create';
  const isFeedSelected = selectedTab === 'explore';
  const isLibrarySelected = selectedTab === 'library';
  const isChatSelected = selectedTab === 'chat';

  return (
    <Container shouldReduceHeight={shouldHideNavigationbarSlightly}>
      <HomeIcon
        isSelected={isHomeSelected}
        onClick={(e) => handleNavHome(e)}
        shouldHideText={shouldHideNavigationbarSlightly}
      />
      <CreateIcon
        isSelected={isCreateSelected}
        onClick={(e) => handleNavCreate(e)}
        shouldHideText={shouldHideNavigationbarSlightly}
      />
      <ExploreIcon
        isSelected={isFeedSelected}
        onClick={(e) => handleNavFeed(e)}
        shouldHideText={shouldHideNavigationbarSlightly}
      />
      <LibraryIcon
        isSelected={isLibrarySelected}
        onClick={(e) => handleClick3(e)}
        shouldHideText={shouldHideNavigationbarSlightly}
      />
      <ChatIcon
        isSelected={isChatSelected}
        onClick={(e) => navToChat(e)}
        shouldHideText={shouldHideNavigationbarSlightly}
      />
    </Container>
  );
};

const Container = styled.div<{ shouldReduceHeight: boolean }>`
  position: fixed;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  left: 2px;

  transition: top 0.3s;
  bottom: 0;

  padding-bottom: 26px;

  @supports (-webkit-touch-callout: none) {
    padding-bottom: 36px;
  }
  background-color: ${BACKGROUND_COLOR};
  z-index: 3;

  box-shadow: 0px -3px 6px 0px rgba(255, 255, 255, 0.1);

  width: 99vw;
  //if on desktop, instead of being on bottom, show on left side
  @media (min-width: ${DESKTOP_WIDTH}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    width: 40px;
    bottom: unset;
    left: 0px;

    height: 85%;

    padding-left: 8px;

    box-shadow: none;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: color 0.2s, transform 0.2s ease-in-out; // Added transform transition

  // text wrap
  text-align: center;

  ${desktopMediaQuery} {
    padding: 50px;
    border-right: 1px solid ${LIGHT_GREY_COLOR};
    font-size: 28px;
    &:hover {
      color: ${PRIMARY_COLOR};
    }
  }
`;

const IconText = styled.div`
  font-size: 12px;
`;

const tranisitonToShare = `
  
`;
interface IconProps {
  isSelected: boolean;
  onClick: (e: React.MouseEvent) => void;
  shouldHideText?: boolean;
}

const StyledHomeIcon = styled(FaHome)<{ shouldHideText?: boolean }>`
  ${tranisitonToShare}
  ${mobileMediaQuery} {
    font-size: ${(props) => (props.shouldHideText ? '28px' : '24px')};
  }
`;

const HomeIcon = ({ isSelected, onClick, shouldHideText }: IconProps) => {
  return (
    <IconContainer
      onClick={onClick}
      style={{ marginTop: getIsOnDesktop() ? '8px' : '' }}
    >
      {isSelected ? (
        <StyledHomeIcon color={PRIMARY_COLOR} shouldHideText={shouldHideText} />
      ) : (
        <StyledHomeIcon shouldHideText={shouldHideText} />
      )}
      {!shouldHideText && <IconText>Home</IconText>}
    </IconContainer>
  );
};

const StyledCreateIcon = styled(FaMagic)`
  ${tranisitonToShare}
  font-size: 22px;
  margin-top: 4px;
`;

const CreateIcon = ({ isSelected, onClick, shouldHideText }: IconProps) => {
  return (
    <IconContainer onClick={onClick}>
      {isSelected ? (
        <StyledCreateIcon color={PRIMARY_COLOR} />
      ) : (
        <StyledCreateIcon />
      )}
      {!shouldHideText && <IconText>Create</IconText>}
    </IconContainer>
  );
};

const FeedStyledIcon = styled(MdSearch)<{ shouldHideText?: boolean }>`
  ${tranisitonToShare}
  ${mobileMediaQuery} {
    font-size: ${(props) => (props.shouldHideText ? '30px' : '24px')};
    margin-top: ${(props) => (props.shouldHideText ? '4px' : '0')};
  }
`;

const ExploreIcon = ({ isSelected, onClick, shouldHideText }: IconProps) => {
  return (
    <IconContainer onClick={onClick}>
      {isSelected ? (
        <FeedStyledIcon color={PRIMARY_COLOR} shouldHideText={shouldHideText} />
      ) : (
        <FeedStyledIcon shouldHideText={shouldHideText} />
      )}
      {!shouldHideText && <IconText>Explore</IconText>}
    </IconContainer>
  );
};

const LibraryStyledIcon = styled(MdOutlineCollections)`
  ${tranisitonToShare}
`;

const LibraryIconContainer = styled(IconContainer)`
  ${mobileMediaQuery} {
    margin-left: -4px;
    margin-top: 5px;
  }
`;

const LibraryIcon = ({ isSelected, onClick, shouldHideText }: IconProps) => {
  return (
    <LibraryIconContainer onClick={onClick}>
      {isSelected ? (
        <LibraryStyledIcon color={PRIMARY_COLOR} />
      ) : (
        <LibraryStyledIcon />
      )}
      {!shouldHideText && <IconText>Library</IconText>}
    </LibraryIconContainer>
  );
};

const ChatStyledIcon = styled(MdChat)<{ shouldHideText?: boolean }>`
  ${tranisitonToShare}
  ${mobileMediaQuery} {
    font-size: ${(props) => (props.shouldHideText ? '26px' : '24px')};
  }
`;

const ChatIconContainer = styled(IconContainer)`
  ${mobileMediaQuery} {
    margin-left: -8px;
    margin-top: 5px;
  }
`;

const ChatIcon = ({ isSelected, onClick, shouldHideText }: IconProps) => {
  return (
    <ChatIconContainer onClick={onClick}>
      {isSelected ? (
        <ChatStyledIcon color={PRIMARY_COLOR} shouldHideText={shouldHideText} />
      ) : (
        <ChatStyledIcon shouldHideText={shouldHideText} />
      )}
      {!shouldHideText && <IconText>Chat</IconText>}
    </ChatIconContainer>
  );
};

export default NavigationBar;
