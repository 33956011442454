import styled from 'styled-components';
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../../constants';
import { desktopMediaQuery } from '../../../styleHelpers';
import TransitioningModal from '../TransitioningModal';

export const paymentModalOverlayStyles = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 100,
};

export const paymentModalContentStylesMobile = {
  backgroundColor: BACKGROUND_COLOR,
  width: '95vw',
  maxWidth: '420px',
  margin: 'auto',
  marginTop: '20px',
  maxHeight: '85vh',
};

export const paymentModalContentStylesDesktop = {
  backgroundColor: BACKGROUND_COLOR,
  width: '75vw',
  margin: 'auto',
  marginTop: '20px',
  maxHeight: '85vh',
};

export const ContainerPaymentModal = styled.div<{
  inSubscriptionModal?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: ${({ inSubscriptionModal }) => (inSubscriptionModal ? '80%' : '100%')};
  overflow-y: auto;
  margin-top: 8px;
  padding-top: ${({ inSubscriptionModal }) =>
    inSubscriptionModal ? '16px' : '16px'};

  padding-bottom: ${({ inSubscriptionModal }) =>
    inSubscriptionModal ? '32px' : '8px'};

  ${desktopMediaQuery} {
    padding-top: 16px;
  }
`;

export const LineInBuyModal = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: ${BORDER_COLOR};
  margin-top: 16px;
  margin-bottom: 4px;

  // align self to center
  margin-left: auto;
`;

export const BenefitListItem = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      gap: '8px',
      marginBottom: 4,
    }}
  >
    {children}
  </div>
);

export const BenefitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin-bottom: 16px;
`;

export const Modal = styled(TransitioningModal)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

export const ModalText = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
  color: ${PRIMARY_TEXT_COLOR};
`;
