import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaCog, FaInfoCircle } from 'react-icons/fa';
import { desktopMediaQuery } from '../../styleHelpers';
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from 'react-icons/md';
import { BORDER_COLOR } from '../../constants';
import { useChatContext } from '../../context/ChatContextProvider';
import { DEFAULT_BOT_NAME, DEFAULT_IMAGE_TO_USE } from '../../chatConstants';
import { fadeInWithBounce } from '../../components/ImageStuff/animations';
import { AiOutlineClose } from 'react-icons/ai';
import { CHAT_LIST_PATH } from '../../pathNames';
import { useNavigate } from 'react-router-dom';

interface TopBarInConversationProps {
  setShowSettingsModal: (show: boolean) => void;
  setShowImagesModal: (show: boolean) => void;
  setShowFirstTimeTipsModal: (show: boolean) => void;
}

const TopBarInConversation = ({ 
  setShowSettingsModal,
  setShowImagesModal,
  setShowFirstTimeTipsModal
}: TopBarInConversationProps) => {
  const {
    activeChatId,
    chats,
    isFocusedOnKeyboard,
    setChatInputValue,
  } = useChatContext();

  const activeChat = chats.find((chat) => chat.id === activeChatId);

  const botName = activeChat?.botName || DEFAULT_BOT_NAME;
  const chosenImage = activeChat?.activeImage || DEFAULT_IMAGE_TO_USE;

  const [showBiggerImage, setShowBiggerImage] = useState(true);
  const [imgToRender, setImgToRender] = useState(chosenImage);

  useEffect(() => {
    if (activeChat) {
      setImgToRender(chosenImage);
    } else {
      setImgToRender(DEFAULT_IMAGE_TO_USE);
    }
  }, [activeChat, chosenImage]);

  const handleShowImages = () => {
    setShowImagesModal(true);
  };

  const openSettingsModal = () => {
    setShowSettingsModal(true);
  };

  const openFirstTimeTipsModal = () => {
    setShowFirstTimeTipsModal(true);
  };

  const navigate = useNavigate();
  const handleGoToChatList = () => {
    setChatInputValue('');
    navigate(CHAT_LIST_PATH);
  };

  return (
    <TopBarContainer isFocusedOnKeyboard={isFocusedOnKeyboard}>
      <CloseChatButton onClick={handleGoToChatList} />
      {showBiggerImage ? (
        <ContainerForBigImage>
          <BiggerBotImage src={imgToRender} onClick={handleShowImages} />
          <MakeImageSmallerIcon onClick={() => setShowBiggerImage(false)} />
        </ContainerForBigImage>
      ) : (
        <ContainerForSmallerImage>
          <BotImage src={imgToRender} onClick={handleShowImages} centerImage />
          <BotName onClick={handleShowImages}>{botName}</BotName>
          <MakeImageBiggerIcon onClick={() => setShowBiggerImage(true)} />
        </ContainerForSmallerImage>
      )}
      <SettingsIcon
        onClick={openSettingsModal}
        showBiggerImage={showBiggerImage}
      />
      <FirstTimeTipsIcon onClick={openFirstTimeTipsModal} />
    </TopBarContainer>
  );
};

const CloseChatButton = styled(AiOutlineClose)`
  margin-left: 16px;
  margin-top: 4px;
  font-size: 22px;
  z-index: 12;
  cursor: pointer;
  position: relative;

  ${desktopMediaQuery} {
    font-size: 36px;
    margin-left: 32px;
    &:hover {
      cursor: pointer;
    }
  }
`;

const TopBarContainer = styled.div<{ isFocusedOnKeyboard: boolean }>`
  display: flex;
  align-items: flex-start;
  margin-top: ${(props) => (props.isFocusedOnKeyboard ? '12px' : '12px')};
  padding-top: 2px;
  padding-bottom: 4px;
  border-bottom: 0.5px dotted ${BORDER_COLOR};
  width: 98%;
  position: sticky;
  z-index: 11;
`;

const ContainerForBigImage = styled.div`
  position: relative;
  width: calc(99vw - 52px);
  display: flex;
  justify-content: center;
  animation: ${fadeInWithBounce} 0.1s ease;
  margin-left: 0;
  margin-bottom: 4px;
  z-index: 10;
`;

const MakeImageSmallerIcon = styled(MdKeyboardDoubleArrowUp)`
  position: absolute;
  bottom: 8px;
  right: 8px;
  font-size: 24px;
  z-index: 3;
  cursor: pointer;
`;

const BotImage = styled.img<{ centerImage?: boolean }>`
  object-fit: cover;
  object-position: ${(props) => (props.centerImage ? 'center' : 'top')};
  width: 32px;
  height: 32px;

  ${desktopMediaQuery} {
    width: 64px;
    height: 64px;
  }

  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
`;

const BiggerBotImage = styled(BotImage)`
  width: auto;
  height: 36vh;
  object-fit: contain;

  ${desktopMediaQuery} {
    height: 38vh;
  }

  border-radius: 16px;
  z-index: 10;
`;

const ContainerForSmallerImage = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  cursor: pointer;
  animation: ${fadeInWithBounce} 0.1s ease;
`;

const MakeImageBiggerIcon = styled(MdKeyboardDoubleArrowDown)`
  font-size: 22px;
  margin-left: 8px;
`;

const SettingsIcon = styled(FaCog)<{ showBiggerImage: boolean }>`
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  margin-bottom: 2px;
  z-index: 11;
  font-size: ${(props) => (props.showBiggerImage ? '24px' : '18px')};
`;

const FirstTimeTipsIcon = styled(FaInfoCircle)`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 56px;
  margin-bottom: 2px;
  z-index: 11;
  font-size: 20px;
`;

const BotName = styled.div`
  font-size: 16px;
`;

export default TopBarInConversation;
