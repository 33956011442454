import styled from 'styled-components';
import { StyledButton } from '../../common/StyledButton';

import {
  RiEyeCloseLine,
  RiEyeLine,
  RiLockUnlockLine,
  RiUserLine,
} from 'react-icons/ri';
import { LoginDetails } from '../../../types';
import LoadingIndicator from '../../common/LoadingIndicator';
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  MAX_WIDTH,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../../constants';
import { useState, useEffect } from 'react';
import { desktopMediaQuery } from '../../../styleHelpers';
import { FaSignInAlt } from 'react-icons/fa';
import { FaUserPlus } from 'react-icons/fa6';
import BenefitsToRegister from './BenefitsToRegister';
import useTriggerGoogleAuth from '../../../hooks/userHandling/useHandleGoogleAuthStart';
import { googleIconSVGUrl } from './authModalHelpers';
import { Cookies } from 'react-cookie';
import useHandleGetUser from '../../../hooks/userHandling/useHandleGetUser';
import { trackContinueWithGoogleClicked } from '../../../utils/analytics';

export type Variant = 'login' | 'register';
interface P {
  variant: Variant;
  handleSend: (loginDetails: LoginDetails) => void;
  username: string;
  setUsername: (username: string) => void;
  password: string;
  setPassword: (password: string) => void;

  isLoadingLoggingIn: boolean;
  onClose: () => void;
}

const LoginOrRegisterForm = ({
  variant,
  handleSend,
  username,
  setUsername,
  password,
  setPassword,

  isLoadingLoggingIn,
  onClose,
}: P) => {
  const [eyeIcon, setEyeIcon] = useState<'open' | 'closed'>('closed');
  const handleClickOnEye = () => {
    const passwordField = document.getElementById(
      'password',
    ) as HTMLInputElement;
    if (passwordField.type === 'password') {
      passwordField.type = 'text';
    } else {
      passwordField.type = 'password';
    }
    setEyeIcon(eyeIcon === 'closed' ? 'open' : 'closed');
  };

  const EYE_ZINE = 17;
  const isCreatingAccount = variant === 'register';

  const [isLoadingGoogleLogin, setIsLoadingGoogleLogin] = useState(false);

  const triggerGoogleAuthStart = useTriggerGoogleAuth();

  const handleGetUser = useHandleGetUser();

  useEffect(() => {
    if (!isLoadingGoogleLogin) return;

    const cookiesInstance = new Cookies();
    const checkForToken = setInterval(async () => {
      const token = cookiesInstance.get('token');
      if (token && typeof token === 'string' && token.length > 0) {
        clearInterval(checkForToken);
        try {
          await handleGetUser(token);
          onClose();
        } catch (error) {
          console.error('Error getting user:', error);
          // Handle error silently or notify the user
        }
      }
    }, 1000);
    return () => clearInterval(checkForToken);
  }, [isLoadingGoogleLogin, handleGetUser, onClose]);

  const handleContinueWithGoogle = () => {
    trackContinueWithGoogleClicked();
    setIsLoadingGoogleLogin(true);
    try {
      triggerGoogleAuthStart();
    } catch (error) {
      if (error instanceof Error) {
        alert(
          `An error occurred while trying to authenticate with Google. Please try again. Error details: ${error.message}`,
        );
      }
    }
  };

  return (
    <FormContainer>
      <TitleText>
        {isCreatingAccount ? 'Create an Account 🆕' : 'Welcome back 👋'}
      </TitleText>
      {isCreatingAccount && <BenefitsToRegister />}

      <GoogleButton onClick={handleContinueWithGoogle}>
        {isLoadingGoogleLogin ? <LoadingIndicator /> : 'Continue with Google'}
      </GoogleButton>

      <OrText>Or</OrText>

      <IconAndInputContainer>
        <RiUserLine size={32} />
        <SingleLineInputFieldInForm
          placeholder="Username or Email"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </IconAndInputContainer>
      <IconAndInputContainer
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSend({ username, password });
          }
        }}
      >
        <RiLockUnlockLine size={32} />
        <SingleLineInputFieldInForm
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          id="password"
        />
        <EyeIconContainer>
          {eyeIcon === 'closed' ? (
            <RiEyeCloseLine size={EYE_ZINE} onClick={handleClickOnEye} />
          ) : (
            <RiEyeLine size={EYE_ZINE} onClick={handleClickOnEye} />
          )}
        </EyeIconContainer>
      </IconAndInputContainer>
      {isLoadingLoggingIn ? (
        <LoadingIndicator differentBgColor />
      ) : (
        <SendButton
          variant={variant}
          onClick={() => handleSend({ username, password })}
        />
      )}
    </FormContainer>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 89%;
`;

const TitleText = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  font-size: 24px;
  font-weight: 600;
  color: ${PRIMARY_TEXT_COLOR};
  margin-bottom: 16px;
`;

const GoogleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background: transparent;
  border: 1px solid ${BORDER_COLOR};
  border-radius: 8px;
  color: ${PRIMARY_TEXT_COLOR};
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  padding: 0 12px;
  position: relative;
  width: 100%;
  transition: all 0.2s ease;

  &::before {
    content: '';
    background-image: url(${googleIconSVGUrl});
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
  }

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    background-color: ${BACKGROUND_COLOR};
  }
`;

const OrText = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  color: #666;
  font-size: 14px;
  margin: 16px 0;
  width: 100%;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #dadce0;
  }

  &::before {
    margin-right: 16px;
  }

  &::after {
    margin-left: 16px;
  }
`;

export const SingleLineInputFieldInForm = styled.input`
  border: none;
  width: 100%;
  border-radius: 24px;
  padding: 14px;
  padding-bottom: 10px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  color: ${PRIMARY_TEXT_COLOR};
  background-color: transparent;
  word-break: break-word;
  &:focus {
    outline: none;
  }
  :-webkit-autofill {
    filter: none;
    box-shadow: 0 0 0 40px ${BACKGROUND_COLOR} inset;
    -webkit-text-fill-color: ${PRIMARY_TEXT_COLOR} !important;
  }
  resize: none;
`;

const EyeIconContainer = styled.div`
  margin-top: 4px;
  margin-right: -23px;
  margin-left: -6px;
`;

const IconAndInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  border-radius: 4px;
  border: 1.5px solid ${BORDER_COLOR};
  background-color: transparent;
  padding-left: 4px;

  ${desktopMediaQuery} {
    &:hover {
      border: 1.5px solid ${PRIMARY_COLOR};
    }
  }
`;

interface BP {
  variant: Variant;
  onClick: () => void;
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const SendButton = ({ variant, onClick }: BP) => (
  <StyledButton onClick={onClick}>
    <ButtonContainer>
      {variant === 'login' ? (
        <>
          <FaSignInAlt /> Login
        </>
      ) : (
        <>
          <FaUserPlus />
          Sign Up
        </>
      )}
    </ButtonContainer>
  </StyledButton>
);

export default LoginOrRegisterForm;
