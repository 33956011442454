import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import FeedPage from './pages/FeedPage/FeedPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import Library from './pages/Library/Library';
import InpaintingPage from './pages/Inpainting/InpaintingPage';
import MainApp from './MainApp';
import LandingPage from './pages/LandingPage/LandingPage';
import NotFound from './components/common/NotFound';
import ContentPolicyPage from './pages/Terms/ContentPolicyPage';
import FolderDetail from './pages/Library/FoldersTab/FolderDetail';
import HistoryTab from './pages/Library/HistoryTab';
import SavedImagesTab from './pages/Library/SavedImagesTab';
import FoldersTab from './pages/Library/FoldersTab/FoldersTab';
import Redirecting from './pages/Redirecting/Redirecting';
import ProfileDetail from './pages/ProfilePage/PublicProfile/ProfileDetail';
import GeneratePage from './pages/GeneratePage/GeneratePage';
import HomePage from './pages/HomePage/HomePage';
import DisputeResolutionPage from './pages/Terms/DisputeResolutionPage';
import BlogPostsPage from './pages/Blog/BlogPostsPage';
import IndividualBlogPost from './pages/Blog/IndividualBlogPost';
import ChatConversation from './pages/ChatPage/ChatConversation';
import ChatsList from './pages/ChatPage/ChatList';
import ChatPage from './pages/ChatPage/ChatPage';
import RefundPolicy from './pages/Terms/RefundPolicy';
import PrivacyPolicy from './pages/Terms/PrivacyPolicy';
import TermsAndConditions from './pages/Terms/TermsAndConditions';
import CompleteGoogleAuth, { COMPLETE_GOOGLE_AUTH_ROUTE } from './pages/Redirecting/CompleteGoogleAuth';

const Routes = () => {
  return (
    <Switch>
      <Route index element={<LandingPage />} />
      <Route path="/blog" element={<BlogPostsPage />} />
      <Route path="/blog/:slug" element={<IndividualBlogPost />} />

      <Route path="/content-policy" element={<ContentPolicyPage />} />
      <Route path="/dispute-resolution" element={<DisputeResolutionPage />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/app" element={<MainApp />}>
        <Route index element={<HomePage />} />

        <Route path={COMPLETE_GOOGLE_AUTH_ROUTE} element={<CompleteGoogleAuth />} />

        <Route path="generate" element={<GeneratePage />} />
        <Route path="create" element={<Navigate to="/app/generate" />} />
        <Route path="inpainting" element={<InpaintingPage />} />

        <Route path="library" element={<Library />}>
          <Route path="history" element={<HistoryTab />} />
          <Route path="saved" element={<SavedImagesTab />} />
          <Route path="folders" element={<FoldersTab />} />
          <Route path="folders/:folderId" element={<FolderDetail />} />
        </Route>
        <Route path="feed" element={<FeedPage />} />
        <Route path="feed/:channelName" element={<FeedPage />} />
        <Route path="profile/:userId" element={<ProfileDetail />} />
        <Route path="profile" element={<ProfilePage />}>
          <Route path="signup" element={<ProfilePage />} />
        </Route>

        <Route path="chat" element={<ChatPage />}>
          <Route path="list" element={<ChatsList />} />
          <Route path="conversation" element={<ChatConversation />} />
        </Route>
      </Route>

      <Route path="/redirect" element={<Redirecting />} />
      <Route path="*" element={<NotFound />} />
    </Switch>
  );
};

export default Routes;
