import { getBaseUrlForFetching } from '../../App';
import { waitSeconds } from '../../components/helpers';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { useImagesContext } from '../../context/ImagesContextProvider';
import { FakeImage } from '../../types';

import { headers } from './helpers';

const WAIT_TIME = 3;

const AMOUNT_OF_RETRIES = 12;

const useGetImageDataWithTrackId = () => {
  const { cancelled, setCancelled, generationReadyTimestamp } =
    useGeneralContext();

  const { failedTrackIds, setFailedTrackIds } = useImagesContext();

  const shouldStartFetching = () => {
    let msToMinusFromTimestamp = 4000;
    const now = Date.now();
    console.log(now, generationReadyTimestamp, now >= generationReadyTimestamp - msToMinusFromTimestamp)
    return now >= generationReadyTimestamp - msToMinusFromTimestamp;
  };

  const getImageDataFromFutureUrl = async (
    fakeImage: FakeImage,
  ): Promise<FakeImage> => {
    if (!fakeImage.futureUrl) {
      return fakeImage;
    }

    while (true) {
      if (!shouldStartFetching()) {
        await waitSeconds(WAIT_TIME);
        continue;
      }

      const imageToReturn: FakeImage = {
        ...fakeImage,
        imageUrl: fakeImage.futureUrl,
        generating: false,
      };

      return imageToReturn;
    }
  };

  const getImageDataFromTrackId = async (
    trackId: string,
  ): Promise<FakeImage | null | 'cancelled'> => {
    let retries = 0;

    const functionName = 'getImageData';

    const baseUrl = getBaseUrlForFetching();

    while (true) {
      if (failedTrackIds.includes(trackId)) {
        return 'cancelled';
      }
      if (cancelled) {
        setCancelled(false);
        return 'cancelled';
      }
      if (!shouldStartFetching()) {
        await waitSeconds(WAIT_TIME);
        continue;
      }

      const fetchUrl = `${baseUrl}/${functionName}`;
      try {
        const response = await fetch(fetchUrl, {
          method: 'POST',
          body: JSON.stringify({ trackId }),
          headers,
        });

        // to omany requests
        if (response.status === 429) {
          await waitSeconds(WAIT_TIME);
          continue;
        }

        if (response.status === 200) {
          const data = (await response.json()) as FakeImage;
          // if url is "generating", wait a bit and try again
          if (data.imageUrl === 'generating') {
            console.log("data.imageUrl === 'generating'");
            retries++;

            if (retries > AMOUNT_OF_RETRIES) {
              console.log('Setting failed trackids after generating');
              setFailedTrackIds((s) => [...s, trackId]);
              return null;
            }

            await waitSeconds(WAIT_TIME);
            continue;
          }

          return data;
        }

        if (response.status === 404) {
          await waitSeconds(WAIT_TIME);
          console.log('response status 404');
          if (retries > AMOUNT_OF_RETRIES) {
            console.log('setting failed trackids');
            setFailedTrackIds((s) => [...s, trackId]);
            return 'cancelled';
          }
          continue;
        }
      } catch {
        retries++;
        if (retries > AMOUNT_OF_RETRIES) {
          return null;
        }
        await waitSeconds(WAIT_TIME);
        continue;
      }
    }
  };
  return {getImageDataFromTrackId, getImageDataFromFutureUrl};
};

export default useGetImageDataWithTrackId;
