import { getBaseUrlForFetching, SITE_BASE_URL } from '../../App';
import { COMPLETE_GOOGLE_AUTH_ROUTE } from '../../pages/Redirecting/CompleteGoogleAuth';

/**
 * This is called from AuthModal's Form
 * It opens a new window to the Google OAuth2 authorization page
 * The redirectUrl is the URL that the user will be redirected to after the OAuth2 flow is complete
 * That redirect url is the COMPLETE_GOOGLE_AUTH_ROUTE route, which is defined in CompleteGoogleAuth.tsx
 * That component handles the OAuth2 flow completion and then closes itself after completion
 */
const useTriggerGoogleAuth = (): (() => void) => {
  const triggerGoogleAuth = (): void => {
    const redirectUrl = `${SITE_BASE_URL}/app/${COMPLETE_GOOGLE_AUTH_ROUTE}`
    window.open(
      `${getBaseUrlForFetching()}/triggerGoogleAuth?redirectUrl=${encodeURIComponent(
        redirectUrl,
      )}`,
      '_blank',
    );
  };

  return triggerGoogleAuth;
};

export default useTriggerGoogleAuth;
