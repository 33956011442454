import { createContext, useContext, useEffect, useState } from 'react';
import { ChatState, GenericSetState } from '../types';

import ChatSettingsStorage from '../localStorage/chatSettingsStorage';

interface GlobalChatContext {
  activeChatId: string; // The ID of the currently active chat
  chats: ChatState[]; // Array of all chat states
  setActiveChat: (chatId: string) => void; // Function to set the active chat
  addNewChat: (newChat: ChatState) => void; // Function to add a new chat
  updateChat: (chatId: string, updatedChat: Partial<ChatState>) => void; // Function to update an existing chat
  deleteChat: (chatId: string) => void; // Function to delete a chat

  userName: string;
  setUserName: (name: string) => void;

  chatInputValue: string;
  setChatInputValue: GenericSetState<string>;

  isEditingBotMessage: boolean;
  setIsEditingBotMessage: GenericSetState<boolean>;

  // Global state variables
  botIsTyping: boolean;
  setBotIsTyping: GenericSetState<boolean>;

  isFocusedOnKeyboard: boolean;
  setIsFocusedOnKeyboard: GenericSetState<boolean>;

  isDoingAction: boolean;
  setIsDoingAction: GenericSetState<boolean>;

  showSettingsModal: boolean;
  setShowSettingsModal: GenericSetState<boolean>;

  showImagesModal: boolean;
  setShowImagesModal: GenericSetState<boolean>;
}

// Default state for the global chat context
const defaultState: GlobalChatContext = {
  activeChatId: '',
  chats: [],
  setActiveChat: () => {},

  addNewChat: () => {},
  updateChat: () => {},
  deleteChat: () => {},

  userName: '',
  setUserName: () => {},

  chatInputValue: '',
  setChatInputValue: () => {},

  isEditingBotMessage: false,
  setIsEditingBotMessage: () => {},

  botIsTyping: false,
  setBotIsTyping: () => {},

  isFocusedOnKeyboard: false,
  setIsFocusedOnKeyboard: () => {},

  isDoingAction: false,
  setIsDoingAction: () => {},

  showSettingsModal: false,
  setShowSettingsModal: () => {},

  showImagesModal: false,
  setShowImagesModal: () => {},
};

const Context = createContext<GlobalChatContext>(defaultState);

export const useChatContext = () => useContext(Context);

// @ts-ignore: children prop is correctly passed
const ChatContextProvider = ({ children }) => {
  const chatSettingsStorage = new ChatSettingsStorage();

  const [chats, setChats] = useState<ChatState[]>([]);

  useEffect(() => {
    const storedChats = chatSettingsStorage.getChats();
    if (storedChats) {
      setChats(storedChats);
    }
  }, []);

  const [activeChatId, setActiveChatId] = useState<string>('');

  const setActiveChat = (chatId: string) => {
    setActiveChatId(chatId);
    chatSettingsStorage.setActiveChatId(chatId);
  };

  const addNewChat = (newChat: ChatState) => {
    setChats((prevChats) => [newChat, ...prevChats]);
    chatSettingsStorage.saveChat(newChat);
  };

  const updateChat = (chatId: string, updatedChat: Partial<ChatState>) => {
    setChats((prevChats) =>
      prevChats.map((chat) =>
        chat.id === chatId ? { ...chat, ...updatedChat } : chat,
      ),
    );
    chatSettingsStorage.updateChat(chatId, updatedChat);
  };

  const deleteChat = (chatId: string) => {
    setChats((prevChats) => prevChats.filter((chat) => chat.id !== chatId));
    chatSettingsStorage.deleteChat(chatId);
    // If the deleted chat was active, reset activeChatId
    if (activeChatId === chatId) {
      setActiveChatId('');
      chatSettingsStorage.setActiveChatId('');
    }
  };

  const [userNameState, setUserNameState] = useState<string>('');

  const userName = chatSettingsStorage.getUserName() || userNameState;

  const setUserName = (name: string) => {
    setUserNameState(name);
    chatSettingsStorage.setUserName(name);
  };

  const [chatInputValue, setChatInputValue] = useState('');

  const [isEditingBotMessage, setIsEditingBotMessage] = useState<boolean>(false);

  const [botIsTyping, setBotIsTyping] = useState<boolean>(false);
  const [isFocusedOnKeyboard, setIsFocusedOnKeyboard] =
    useState<boolean>(false);
  const [isDoingAction, setIsDoingAction] = useState<boolean>(false);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [showImagesModal, setShowImagesModal] = useState<boolean>(false);

  return (
    <Context.Provider
      value={{
        activeChatId,
        chats,
        setActiveChat,
        addNewChat,
        updateChat,
        deleteChat,

        userName,
        setUserName,

        chatInputValue,
        setChatInputValue,

        botIsTyping,
        setBotIsTyping,

        isFocusedOnKeyboard,
        setIsFocusedOnKeyboard,

        isEditingBotMessage,
        setIsEditingBotMessage,

        isDoingAction,
        setIsDoingAction,

        showSettingsModal,
        setShowSettingsModal,

        showImagesModal,
        setShowImagesModal,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ChatContextProvider;
