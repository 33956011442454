import { useNavigate } from 'react-router-dom';
import './TopBar.css';
import Logo from './Logo';
import TopRightCornerPartOfTopBar from './TopRightCornerPartOfTopBar';
import {
  TopBarContainer,
  LeftSideContainerInTopBar,
  RightSideContainerInTopBar,
} from './TopBarCommonComponents';
import { getIsOnDesktop } from '../../../styleHelpers';

const URL_PART_WHEN_TO_HIDE_TOP_BAR = 'feed?id=';
const URL_PART_CHAT = '/chat/conversation';

/**
 * Determines whether the top bar should be hidden based on the current URL.
 * @returns {boolean} `true` if the top bar should be hidden, `false` otherwise.
 */
export const getShouldHideTopBar = () => {
  const url = window.location.href;
  return (
    (url.includes(URL_PART_WHEN_TO_HIDE_TOP_BAR) ||
      url.includes(URL_PART_CHAT)) &&
    !getIsOnDesktop()
  );
};

const TopBar = () => {
  const navigate = useNavigate();

  const handleTakeToLandingPage = () => {
    navigate('/');
  };

  /*
  const shouldUseVisible = window.location.href.includes("character");

  const { isVisible } = useHandleControlNavbar();
  */

  const showTheBar = !getShouldHideTopBar();

  return (
    <TopBarContainer isVisible={showTheBar}>
      <LeftSideContainerInTopBar onClick={handleTakeToLandingPage}>
        <Logo />
      </LeftSideContainerInTopBar>
      <RightSideContainerInTopBar>
        <TopRightCornerPartOfTopBar />
      </RightSideContainerInTopBar>
    </TopBarContainer>
  );
};

export default TopBar;
