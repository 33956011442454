import { useLoggedInUserContext } from '../context/LoggedInUserContextProvider';
import { customFetch } from './customFetch';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface CentrobillRedirectResponse {
  redirectUrl: string;
}

export type PaymentTypes = 'smallCredits' | 'mediumCredits' | 'largeCredits' | 'goldSubscription' | 'firstMonthDiscountGoldSubscription';

const useCreateRedirectUrlForCentrobill = () => {
  const { loggedInUser } = useLoggedInUserContext();

  // GOLD_SUBSCRIPTION, or SMALL_CREDITS, MEDIUM_CREDITS, LARGE_CREDITS
  const createRedirectUrlForCentrobill = async (packageType: PaymentTypes) => {
    const functionName = 'createRedirectUrlForCentrobill';

    const userId = loggedInUser?.id;

    if (!userId) {
      alert('You must be logged in to buy credits');
      return;
    }

    const { redirectUrl } = await customFetch(functionName, {
      packageType,
      userId,
    });

    console.log({ redirectUrl });

    return redirectUrl;
  };

  return createRedirectUrlForCentrobill;
};

export default useCreateRedirectUrlForCentrobill;
