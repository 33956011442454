import { SpeedModeType } from "./types";

export const SLOW_MODE_ENABLED = false;

// Remember to sync with functions/helpers.ts and SpeedModeSelector, HintToRegisterModal
const waitTimeForSpeedModes: { [key: string]: number } = {
  normal: 320,
  fast: 60,
  adTurbo: 45,
  turbo: 15,
};

export const superv1WaitTime = 17;
export const superv1MiniWaitTime = 17;
export const miniAdTurboWaitTime = 35;

export const waitBeforeSendingMiniAdTurbo = 30;

export const getWaitTimeForSpeedMode = (speedMode: SpeedModeType, modelId?: string) => {
  if (modelId === 'superv1') {
    return speedMode === 'turbo' ? superv1WaitTime : waitTimeForSpeedModes[speedMode];
  }
  
  if (modelId === 'superv1mini') {
    if (speedMode === 'turbo') return superv1MiniWaitTime;
    if (speedMode === 'adTurbo') return miniAdTurboWaitTime;
    return waitTimeForSpeedModes[speedMode];
  }
  
  return waitTimeForSpeedModes[speedMode];
}
