import ReactModal from 'react-modal';
import styled, { keyframes } from 'styled-components';
import { LIGHTER_GREY_COLOR } from '../../constants';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

/**
 * This is a modal that fades in and out when it opens and closes.
 */
const TransitioningModal = styled(ReactModal)<{
  isOpen: boolean;
  slower?: boolean;
}>`
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  animation: ${(props) => (props.isOpen ? fadeIn : fadeOut)} 0.3s ease-in-out;
  // if slower, then 0.7s ease-in-out
  animation-duration: ${(props) => (props.slower ? '0.8s' : '0.3s')};
  border: 1px solid ${LIGHTER_GREY_COLOR};
  border-radius: 8px;
  position: relative;

  display: flex;
  flex-direction: column;
`;

export default TransitioningModal;
