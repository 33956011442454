import { toast } from 'react-toastify';
import { MAGIC_PATH, ADVANCED_PATH, INPAINTING_PATH } from '../../pathNames';
import { FakeImage } from '../../types';
import {
  getEngineUsingModelId,
  getIsEngineNotYetAvailable,
} from '../../utils/engineHelpers';
import { useImageGenerationSettingsContext } from '../../context/ImageGenerationSettingsProvider';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { useNavigate } from 'react-router-dom';

const useHandleClickRemix = () => {
  const { setUploadedImageUrl } = useImageGenerationSettingsContext();
  const { setActiveImage, setImages } = useGeneralContext();
  const navigate = useNavigate();
  const handleRemixingImage = (image: FakeImage) => {
    if (!image) return;

    const isEngineNotYetAvailable = getIsEngineNotYetAvailable(
      image.engine?.modelId,
    );
    if (isEngineNotYetAvailable) {
      toast.error(
        'This engine is not yet available (beta engine). Apologies! 🙏',
      );
      return;
    }

    const engine = getEngineUsingModelId(image.engine?.modelId);

    if (engine.isOffline) {
      toast.error(
        'This engine is currently offline. Choose another image! Apologies! 🙏',
      );
      return;
    }

    if (image.uploadedImageUrl) {
      setUploadedImageUrl(image.uploadedImageUrl);
    } else {
      setUploadedImageUrl('');
    }

    const fakeImage = { ...image };
    setActiveImage(fakeImage);
    setImages((images) => images.concat(fakeImage));

    if (image.isInpainted) {
      navigate(INPAINTING_PATH);
      return;
    }

    if (image.parentImageTrackId) {
      fakeImage.parentImageTrackId = image.parentImageTrackId;
    } else {
      fakeImage.parentImageTrackId = image.trackId;
    }

    if (image.selectedTags && image.selectedTags.length > 0) {
      navigate(MAGIC_PATH);
    } else {
      navigate(ADVANCED_PATH);
    }

    window.scrollTo(0, 0);
  };

  return handleRemixingImage;
};

export default useHandleClickRemix;
