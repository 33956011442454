import { useNavigate } from 'react-router-dom';
import { CHAT_CONVERSATION_PATH } from '../../pathNames';
import { ChatState, FakeImage, SharedImage } from '../../types';
import { useChatContext } from '../../context/ChatContextProvider';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_BOT_NAME, DEFAULT_SCENARIO } from '../../chatConstants';
import { toast } from 'react-toastify';
import useModalPrompt from '../../components/Modals/PromptModal/useModalPrompt';
import { 
  TEXT_PROMPT_BOT_NAME,
  BOT_NAME_OPTIONS,
  TEXT_PROMPT_CHAT_SCENARIO,
  TEXT_PROMPT_YOUR_NAME, 
  TEXT_PROMPT_CHAT_SCENARIO_OPTIONS,
  USER_NAME_OPTIONS
} from './chatHelpers';

const useStartChat = () => {
  const { addNewChat, setActiveChat, userName, setUserName, activeChatId, chats } = useChatContext();
  const navigate = useNavigate();
  const promptModal = useModalPrompt();

  const startChatWithSharedImage = async (sharedImage: SharedImage) => {
    if (!sharedImage) return;

    const botName = await promptModal(TEXT_PROMPT_BOT_NAME, BOT_NAME_OPTIONS);
    const chatScenario = await promptModal(TEXT_PROMPT_CHAT_SCENARIO, TEXT_PROMPT_CHAT_SCENARIO_OPTIONS);

    if (!userName) {
      const newName = await promptModal(TEXT_PROMPT_YOUR_NAME, USER_NAME_OPTIONS);
      if (newName) setUserName(newName);
    }

    const fakeImageObjects = sharedImage.album ?? [sharedImage.image];
    const allBotImages = fakeImageObjects.map(image => image.imageUrl);

    const newChat: ChatState = {
      id: uuidv4(),
      messages: [],
      botName: botName || DEFAULT_BOT_NAME,
      chatScenario: chatScenario || DEFAULT_SCENARIO,
      activeImage: sharedImage.image.imageUrl,
      fakeImageObjects,
      allBotImages,
    };
    
    addNewChat(newChat);
    setActiveChat(newChat.id);
    navigate(CHAT_CONVERSATION_PATH);
  };

  const startChatWithFakeImage = async (fakeImage: FakeImage) => {
    if (!fakeImage) return;

    if (fakeImage.imageUrl === '/placeholder.png') {
      toast.error('Please wait for the image to generate to start a chat or use another image');
      return;
    }

    const botName = await promptModal(TEXT_PROMPT_BOT_NAME, BOT_NAME_OPTIONS);
    const chatScenario = await promptModal(TEXT_PROMPT_CHAT_SCENARIO, TEXT_PROMPT_CHAT_SCENARIO_OPTIONS);

    if (!userName) {
      const newName = await promptModal(TEXT_PROMPT_YOUR_NAME, USER_NAME_OPTIONS);
      if (newName) setUserName(newName);
    }

    const newChat: ChatState = {
      id: uuidv4(),
      messages: [],
      botName: botName || DEFAULT_BOT_NAME,
      chatScenario: chatScenario || DEFAULT_SCENARIO,
      activeImage: fakeImage.imageUrl,
      fakeImageObjects: [fakeImage],
      allBotImages: [fakeImage.imageUrl],
    };
    
    addNewChat(newChat);
    setActiveChat(newChat.id);
    navigate(CHAT_CONVERSATION_PATH);
  };

  const startNewChatWithOngoingChat = () => {
    const currentChat = chats.find(chat => chat.id === activeChatId);
    if (!currentChat) return;

    const newChat: ChatState = {
      id: uuidv4(),
      botName: currentChat.botName,
      chatScenario: currentChat.chatScenario,
      messages: [],
      activeImage: currentChat.activeImage,
      fakeImageObjects: currentChat.fakeImageObjects,
      allBotImages: currentChat.allBotImages
    };

    addNewChat(newChat);
    setActiveChat(newChat.id);
    navigate(CHAT_CONVERSATION_PATH);
  };

  return { startChatWithSharedImage, startChatWithFakeImage, startNewChatWithOngoingChat };
};

export default useStartChat;
