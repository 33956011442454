import { useEffect, useState } from 'react';
import styled from 'styled-components';
import LoadingAnimation from '../../components/common/LoadingStuff/LoadingAnimation';
import {
  DESKTOP_WIDTH,
  PRIMARY_COLOR,
  SECONDARY_TEXT_COLOR,
} from '../../constants';
import { FeedChannel, SharedImage } from '../../types';
import TextFilter from '../../components/common/TextFilter';
import { useLocation, useParams } from 'react-router-dom';
import FeedImagePage from './FeedImagePage/FeedImagePage';
import SharedImagesGrid, {
  StyledFilterIcon,
} from '../../components/ImageStuff/ImageGrid/SharedImagesGrid';
import SortDropdownInFeed from './SortDropdownInFeed';
import useGetPostedImages from '../../hooks/feed/useGetPostedImages';
import {
  filterImages,
  filterImagesByAlreadySeen,
  filterImagesByButton,
} from './feedPageHelpers';
import ChannelsListInFeedTopbar from './ChannelsListInFeedTopbar';
import { desktopMediaQuery } from '../../styleHelpers';
import useFeedChannels from '../../hooks/feed/useFeedChannels';
import FilterButton from './FilterButton';
import { useImagesContext } from '../../context/ImagesContextProvider';
import userSettingsInStorage from '../../localStorage/userSettingsInStorage';
import useInitializeFullScreenTrigger from '../../hooks/misc/useInitializeFullScreenTrigger';
import { useModalsContext } from '../../context/ModalsContextProvider';

const FeedPage = () => {
  const location = useLocation();
  const { channelName: channelNameFromParams } = useParams();
  const params = new URLSearchParams(location.search);
  const imageIdFromParams = params.get('id');
  const imageUrlFromParams = params.get('imageUrl');

  const [allImages, setAllImages] = useState<SharedImage[]>([]);
  const [imagesToRender, setImagesToRender] = useState<SharedImage[]>([]);
  const [isLoadingFeed, setIsLoadingFeed] = useState(false);

  const {
    selectedChannel,
    setSelectedChannel,
    selectedSortOption,
    setSelectedSortOption,
    selectedCategory,
    setSelectedCategory,
    selectedEngine,
    setSelectedEngine,
  } = useImagesContext();

  const { setShowChannelListModal } = useModalsContext();

  const getPostedImages = useGetPostedImages();

  const { fetchFeedChannels } = useFeedChannels();
  const [channelList, setChannelList] = useState<FeedChannel[]>([]);

  useEffect(() => {
    const fetchChannels = async () => {
      const channels = await fetchFeedChannels();
      channels && setChannelList(channels);
    };

    fetchChannels();
  }, []);

  useEffect(() => {
    if (channelNameFromParams && channelList.length > 0) {
      const channelsToFilterOut = ['incest'];
      if (channelsToFilterOut.includes(channelNameFromParams)) {
        setSelectedChannel('all');
        // Remove channel parameter from URL
        const newUrl = new URL(window.location.href);
        newUrl.pathname = 'app/feed';
        window.history.replaceState({}, '', newUrl.toString());
        return;
      }

      const channelExists = channelList.some(
        (channel) =>
          channel.name.toLowerCase() === channelNameFromParams.toLowerCase(),
      );
      if (channelExists) {
        setSelectedChannel(channelNameFromParams);
      } else {
        setSelectedChannel('all');
      }
    } else if (!selectedChannel) {
      setSelectedChannel('all');
    }
  }, [channelNameFromParams, channelList, setSelectedChannel, selectedChannel]);

  const userSettingsStorage = userSettingsInStorage();

  useEffect(() => {
    const lastUsedSort = userSettingsStorage.lastUsedSortOptioninFeed.get();
    if (lastUsedSort) {
      setSelectedSortOption(lastUsedSort);
      return;
    }
    if (!selectedSortOption) {
      const hasAlreadyVisitedFeed = localStorage.getItem(
        'hasAlreadyVisitedFeed',
      );
      const sortToSet = hasAlreadyVisitedFeed ? 'hot' : 'top';

      if (!hasAlreadyVisitedFeed && (selectedChannel === 'all' || selectedChannel === undefined)) {
        setShowChannelListModal(true);
      }

      setSelectedSortOption(sortToSet);
    }
  }, []);

  useEffect(() => {
    if (channelNameFromParams) return;

    if (!selectedChannel) {
      setSelectedChannel('all');
    }
  }, []);

  useEffect(() => {
    const fetchAndSetImages = async () => {
      if (imageIdFromParams) return;
      setIsLoadingFeed(true);

      // hack to make sure it doesn't fetch yet when the selectedChannel state isn't set yet by channelNameFromParams
      if (channelNameFromParams && selectedChannel === 'all') return;

      if (!selectedChannel || !selectedSortOption) return;

      const images = await getPostedImages({
        sort: selectedSortOption,
        channelName: selectedChannel,
        channelList,
      });

      setAllImages(images);
      setIsLoadingFeed(false);
      localStorage.setItem('hasAlreadyVisitedFeed', 'true');
    };

    channelList.length !== 0 && fetchAndSetImages();
  }, [channelList, selectedChannel, selectedSortOption, imageIdFromParams]);

  const { indexOfLastImage } = useImagesContext();

  const [blockFromScrolling, setBlockFromScrolling] = useState(false);

  // These are helpers to block scrolling back to the previous spot when user changes channel or sort option
  // (it should be back at the top when changing, and not scroll back down!)
  useEffect(() => {
    setBlockFromScrolling(false);
  }, [indexOfLastImage]);

  useEffect(() => {
    setBlockFromScrolling(true);
  }, [selectedChannel, selectedSortOption]);

  const [promptFilter, setPromptFilter] = useState<string>('');

  const shouldFilterSeen =
    selectedSortOption === 'hot' && allImages.length > 50;

  useEffect(() => {
    if (imageIdFromParams) return;

    setIsLoadingFeed(true);
    const imagesFilteredByButton = filterImagesByButton(
      allImages,
      selectedCategory,
      selectedEngine,
    );

    const imagesFilteredByPrompt = filterImages(
      imagesFilteredByButton,
      promptFilter,
    );

    const imagesFilteredByAlreadySeen = filterImagesByAlreadySeen(
      imagesFilteredByPrompt,
      shouldFilterSeen,
    );

    // If someone goes through all images, maybe we should just reset the seen images (also there's apparently some bug that causes all images to be marked as seen)
    if (imagesFilteredByAlreadySeen.length === 0 && selectedChannel === 'all') {
      //AlreadySeenImagesStorage.clearStorage();
      // TODO: some other check here as well since the feed starts as empty...
    }

    setImagesToRender(imagesFilteredByAlreadySeen);
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsLoadingFeed(false);
    }, 100);
  }, [selectedCategory, selectedEngine, promptFilter, allImages]);

  const handleSetPromptFilter = (promptFilterInput: string) => {
    setPromptFilter(promptFilterInput);
  };

  const isFilterSelected = !(
    (selectedCategory === 'All' || selectedCategory === undefined) &&
    (selectedEngine === '' || selectedEngine === undefined)
  );

  useEffect(() => {
    if (!channelNameFromParams) return;
    const title = `Browse NSFW ${channelNameFromParams} images generated using our AI`;
    const description = `Discover a curated selection of uncensored ${channelNameFromParams} AI-generated images in high quality. Browse and generate a collection of unique AI-generated content.`;

    document.title = title;

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', description);
    } else {
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.name = 'description';
      newMetaDescription.content = description;
      document.head.appendChild(newMetaDescription);
    }
  }, [channelNameFromParams]);

  const transofrmedChannelName =
    channelNameFromParams && channelNameFromParams.endsWith('s')
      ? channelNameFromParams.slice(0, -1)
      : channelNameFromParams;

  const SEOtextToRender =
    channelNameFromParams &&
    `You're now browsing #${channelNameFromParams} with AI-generated nude ${transofrmedChannelName} images`;

  const renderClickToShowThings =
    selectedSortOption === 'top' && !isFilterSelected;

  useInitializeFullScreenTrigger();

  const handleSelectCategoryOnSEO = (category: string) => {
    setSelectedCategory(category);
    setSelectedEngine('');
  };

  // See SharedImagesGrid.tsx and useShareImage.tsx to see how this is used
  if (imageIdFromParams) {
    return (
      <FeedImagePage
        imageId={imageIdFromParams}
        imageUrl={imageUrlFromParams}
      />
    );
  }

  if (channelList.length === 0)
    return <LoadingAnimation loading loaderToChoose={2} />;

  return (
    <Container>
      <FeedtopBarContainer>
        <SortAndSearchContainer>
          <SortDropdownInFeed />
          <TextFilter
            filter={promptFilter}
            handleSetFilter={handleSetPromptFilter}
          />
          <FilterButton isFilterSelected={isFilterSelected} />
        </SortAndSearchContainer>

        <ChannelsListInFeedTopbar channels={channelList} />
      </FeedtopBarContainer>

      {isLoadingFeed ? (
        <LoadingAnimation loading={isLoadingFeed} loaderToChoose={5} />
      ) : (
        <div>
          {isFilterSelected && (
            <SEOPart>
              Note: A filter is active{' '}
              <StyledFilterIcon color={PRIMARY_COLOR} />
              <br />
              Turn it off to view all images
            </SEOPart>
          )}

          {SEOtextToRender && (
            <SEOPart>
              {SEOtextToRender} <br />
            </SEOPart>
          )}

          {renderClickToShowThings && (
            <SEOPart>
              <div
                onClick={() => handleSelectCategoryOnSEO('realistic')}
                style={{ color: PRIMARY_COLOR, cursor: 'pointer' }}
              >
                Click to show only realistic images
              </div>
              <div
                onClick={() => handleSelectCategoryOnSEO('anime')}
                style={{ color: PRIMARY_COLOR, cursor: 'pointer' }}
              >
                Click to show only anime images
              </div>
            </SEOPart>
          )}
          <SharedImagesGrid
            sharedImages={imagesToRender}
            shouldMarkAsSeen={shouldFilterSeen}
            blockFromScrolling={blockFromScrolling}
          />
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 100px;
  padding-left: 8px;
  padding-right: 8px;

  @media (min-width: ${DESKTOP_WIDTH}px) {
    margin-left: 15vw;
    max-width: 80vw;
  }
`;

const FeedtopBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 95vw;

  margin-bottom: 12px;
`;

const SortAndSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  margin-bottom: -2px;

  ${desktopMediaQuery} {
    max-width: 78vw;
    margin-left: 5vw;
  }
`;

const SEOPart = styled.div`
  text-align: center;
  color: ${SECONDARY_TEXT_COLOR};
  margin-bottom: 16px;
`;

export default FeedPage;
