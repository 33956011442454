import React from 'react';
import styled from 'styled-components';
import { BORDER_COLOR, PRIMARY_TEXT_COLOR, SECONDARY_TEXT_COLOR } from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';

interface ChatListItemProps {
  id: string
  activeImage: string
  botName: string
  onChatClick: (chatId: string) => void
  getTimestampForLastMessage: (chatId: string) => string
  getPreviewForLastMessage: (chatId: string) => string
}

export const ChatListItem: React.FC<ChatListItemProps> = ({
  id,
  activeImage,
  botName,
  onChatClick,
  getTimestampForLastMessage,
  getPreviewForLastMessage
}) => (
  <ChatItem onClick={() => onChatClick(id)}>
    <Avatar src={activeImage} alt={botName} />
    <ChatInfo>
      <ChatHeader>
        <ChatName>{botName}</ChatName>
        <ChatTimestamp>
          {getTimestampForLastMessage(id)}
        </ChatTimestamp>
      </ChatHeader>
      <ChatSnippet>{getPreviewForLastMessage(id)}</ChatSnippet>
    </ChatInfo>
  </ChatItem>
);

const ChatItem = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
  padding: 16px;
  cursor: pointer;
  border-bottom: 0.5px dotted ${BORDER_COLOR};
  transition: scale 0.2s ease-in-out;

  &:hover {
    scale: 1.02;
  }

  ${desktopMediaQuery} {
    width: 80%;
  }
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
`;

const ChatInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ChatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChatName = styled.span`
  font-weight: bold;
  color: ${PRIMARY_TEXT_COLOR};
  font-size: 16px;
`;

const ChatTimestamp = styled.span`
  font-size: 12px;
  color: ${SECONDARY_TEXT_COLOR};
`;

const ChatSnippet = styled.span`
  margin-top: 4px;
  font-size: 14px;
  color: ${SECONDARY_TEXT_COLOR};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`; 