import styled from 'styled-components';

import { toast } from 'react-toastify';
import useSendMessage from '../../hooks/chat/useSendMessage';
import { ChatMessage } from '../../types';
import { useChatContext } from '../../context/ChatContextProvider';
import {
  userRole,
  HIDDEN_TYPE_MSG,
  ONBOARDING_TYPE_MSG,
  USER_MESSAGE_COLOR,
  BOT_MESSAGE_COLOR,
  FIRST_HIDDEN_MESSAGE,
  BOT_MESSAGE_COLOR_DIMMED,
  USER_MESSAGE_COLOR_DIMMED,
} from '../../chatConstants';
import { fadeInWithBounce } from '../../components/ImageStuff/animations';
import { PRIMARY_TEXT_COLOR } from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';
import ActionButtonsInMessage from './ActionButtonsInMessage';
import { FaEdit } from 'react-icons/fa';

const MESSAGE_DELETION_STORAGE_KEY = 'messageDeletionConfirmCount';

const shouldShowDeletionConfirm = (): boolean => {
  const count = Number(localStorage.getItem(MESSAGE_DELETION_STORAGE_KEY) || '0');
  return count < 2;
};

const incrementDeletionConfirmCount = (): void => {
  const count = Number(localStorage.getItem(MESSAGE_DELETION_STORAGE_KEY) || '0');
  localStorage.setItem(MESSAGE_DELETION_STORAGE_KEY, String(count + 1));
};

interface MessageBubbleProps {
  message: ChatMessage;
  isLastMessage?: boolean;
  //timestamp
}

const MessageBubble = ({ message, isLastMessage }: MessageBubbleProps) => {
  const {
    activeChatId,
    chats,
    updateChat,
    botIsTyping,
    setBotIsTyping,
    userName,
    setChatInputValue,
    setIsEditingBotMessage,
  } = useChatContext();

  const activeChat = chats.find((chat) => chat.id === activeChatId);

  if (!activeChat) {
    return null;
  }

  const { botName, messages } = activeChat;

  const sendMessage = useSendMessage();
  const isUser = message.role === 'user';
  const roleToRender = isUser ? userName : botName;

  const setMessages = (newMessages: ChatMessage[]) => {
    updateChat(activeChatId, { messages: newMessages });
  };

  const hiddenRetryMessage = (): ChatMessage => ({
    role: userRole,
    content: 'Please retry',
    type: HIDDEN_TYPE_MSG,
    timestamp: new Date().toISOString(),
  });

  const handleRetryMessage = async () => {
    setBotIsTyping(true);
    const slicedMessages = messages.length > 0 ? messages.slice(0, -1) : [];
    const newMessages = [...slicedMessages, hiddenRetryMessage()];
    setMessages(newMessages);
    const responseToMessage = await sendMessage(newMessages);
    if (responseToMessage) {
      setMessages([...newMessages, responseToMessage]);
    } else {
      toast.error('Error retrying');
    }
    setBotIsTyping(false);
  };

  const handleEditMessage = (isEditingBotMessage?: boolean) => {
    if (shouldShowDeletionConfirm() && !isEditingBotMessage) {
      const confirmDeletion = window.confirm(
        'This will remove messages after the one you are editing. Continue?',
      );
      if (!confirmDeletion) return;
      incrementDeletionConfirmCount();
    }

    const messageIndex = messages.findIndex(
      (m) => m.timestamp === message.timestamp,
    );
    if (messageIndex === -1) return;

    const newMessages = messages.slice(0, messageIndex);
    setMessages(newMessages);
    updateChat(activeChatId, { messages: newMessages });
    setChatInputValue(message.content);
  };

  const handleEditBotMessage = () => {
    setIsEditingBotMessage(true);
    handleEditMessage(true);
  };

  const hiddenContinueMessage = (): ChatMessage => ({
    role: userRole,
    content: 'Please continue, avoid repeating yourself',
    type: HIDDEN_TYPE_MSG,
    timestamp: new Date().toISOString(),
  });

  const handleContinue = async () => {
    setBotIsTyping(true);
    const messagesToSend = [...messages, hiddenContinueMessage()];
    const responseToMessage = await sendMessage(messagesToSend);
    if (responseToMessage) {
      setMessages([...messagesToSend, responseToMessage]);
    }
    setBotIsTyping(false);
  };

  const showActionButtons = !isUser && isLastMessage;
  const showUsersActionButtons = isUser;

  const isOnboardingMessage = message.type === ONBOARDING_TYPE_MSG;
  const hideMessage =
    message.type === HIDDEN_TYPE_MSG || message.type === FIRST_HIDDEN_MESSAGE;

  if (hideMessage) return null;
  return (
    <MessageContainer isUser={isUser}>
      <MessageSender>{roleToRender}</MessageSender>
      <MessageContent
        isUser={isUser}
        dangerouslySetInnerHTML={{
          __html: processContent(message.content, isUser),
        }}
      />

      {showUsersActionButtons && (
        <EditButtonForUsersMessage onClick={() => handleEditMessage()}>
          <FaEdit />
        </EditButtonForUsersMessage>
      )}

      {showActionButtons && !botIsTyping && !isOnboardingMessage && (
        <ActionButtonsInMessage
          handleRetryMessage={handleRetryMessage}
          handleContinue={handleContinue}
          handleEditMessageBotMessage={handleEditBotMessage}
        />
      )}
    </MessageContainer>
  );
};

export default MessageBubble;

const MessageContainer = styled.div<{ isUser?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 4px;
  max-width: 82vw;

  ${desktopMediaQuery} {
    max-width: 35vw;
  }

  margin-left: ${({ isUser }) => (isUser ? 'auto' : '0')};

  position: relative;
`;

const MessageSender = styled.div`
  font-size: 14px;
  margin-left: 4px;
`;

const processContent = (content: string, isUser: boolean) => {
  // Sanitize content to prevent XSS attacks
  const sanitizedContent = content.replace(/</g, '&lt;').replace(/>/g, '&gt;');

  // Replace *text* with styled span that has both italic and color
  const withEmphasisAndColor = sanitizedContent.replace(
    /\*(.*?)\*/g,
    `<span style="color: ${
      isUser ? USER_MESSAGE_COLOR_DIMMED : BOT_MESSAGE_COLOR_DIMMED
    }; font-style: italic">*$1*</span>`,
  );

  // Replace newlines with <br>
  return withEmphasisAndColor.replace(/\n/g, '<br />');
};

const MessageContent = styled.div<{ isUser?: boolean }>`
  animation: ${fadeInWithBounce} 0.3s ease-in-out;
  background-color: ${({ isUser }) =>
    isUser ? USER_MESSAGE_COLOR : BOT_MESSAGE_COLOR};
  border-radius: ${({ isUser }) =>
    isUser ? '16px 0px 16px 16px' : '0px 16px 16px 16px'};
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  color: ${PRIMARY_TEXT_COLOR};

  font-size: 16px;

  ${desktopMediaQuery} {
    font-size: 18px;
  }
`;

const EditButtonForUsersMessage = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.01);
  width: 50px;
  padding: 4px 12px;
  border: none;
  border-radius: 16px;

  cursor: pointer;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
    color: ${PRIMARY_TEXT_COLOR};
  }
`;
