import React, { useEffect } from 'react';
import { customFetch } from '../../hooks/customFetch';
import useSetUserCookie from '../../hooks/userHandling/useSetUserCookie';

export const COMPLETE_GOOGLE_AUTH_ROUTE = 'complete-google-auth';

const CompleteGoogleAuth: React.FC = () => {
  const setUserCookie = useSetUserCookie();
  useEffect(() => {
    const handleGoogleAuth = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get('code');

      if (!code) return;

      try {
        const { token } = await customFetch(
          'returnUserTokenWithGoogleAuthCode',
          { code },
        );
        if (token) {
          setUserCookie(token);
          window.close();
        }
      } catch (error) {
        console.error('Error completing Google auth:', error);
      }
    };

    handleGoogleAuth();
  }, []);

  return <div></div>;
};

export default CompleteGoogleAuth;
